import { DialogMixin } from '@/mixins/dialog';
import { BatchEditPriceForm, BatchEditProp, StrategyWay } from '@/resource/model';
import { translation } from '@/utils';
import { ElForm } from 'element-ui/types/form';
import { cloneDeep } from 'lodash-es';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class BatchEditPrice extends mixins(DialogMixin) {
  /**
   * 是否需要选择策略
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly strategy!: boolean;

  public form: BatchEditPriceForm = {
    amount: 0,
    prop: 'priceBeforeTax',
    strategy: 'default'
  };

  public formRules: { [P in keyof BatchEditPriceForm]: Array<Object> } = {
    amount: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value && value !== 0) {
            callback(new Error(translation('billing.inputAmount')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    prop: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectProp')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    strategy: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectStrategy')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public props: Array<{ value: keyof BatchEditProp; label: string }> = [
    {
      value: 'priceBeforeTax',
      label: `billing.priceBeforeTax`
    },
    {
      value: 'priceIncludingTax',
      label: `billing.priceIncludingTax`
    },
    {
      value: 'amountExcludingTax',
      label: `billing.amountExcludingTax`
    }
    // {
    //   value: 'amountIncludingTax',
    //   label: `billing.amountIncludingTax`
    // }
  ];

  public strategies: Array<{ value: StrategyWay; label: string }> = [
    {
      value: 'default',
      label: `billing.defaultStrategy`
    },
    {
      value: 'byArea',
      label: `billing.byArea`
    },
    {
      value: 'byNum',
      label: `billing.byNum`
    }
  ];

  public get precisionNum(): number {
    const sixPrecisionProp = ['priceIncludingTax', 'priceBeforeTax'];
    return sixPrecisionProp.includes(this.form.prop) ? 6 : 2;
  }

  /**
   * 是否显示策略选框
   */
  public get showStrategy(): boolean {
    return this.strategy && this.form.prop === 'amountExcludingTax';
  }

  public dialogClosed(): void {
    (this.$refs.editPriceForm as ElForm).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.editPriceForm as ElForm).validate((valid: boolean) => {
      if (!valid) {
        return;
      }
      this.$emit('edit-confirm', cloneDeep(this.form));
      this.closeDialog();
    });
  }
}
