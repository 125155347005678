import { billingService, projectShopService } from '@/api';
import { ProjectShopQuery, ProjectShopResource } from '@/resource/model';
import { Component, InjectReactive } from 'vue-property-decorator';
import { Paging } from '@/api/base';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTable } from '@/components';
import { convertProvinces, messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { DialogMixin } from '@/mixins/dialog';

@Component({
  name: 'SelectProjectShop'
})
export default class SelectProjectShop extends mixins(DialogMixin) {
  public pageTotal = 0;

  public tableOption: OsTableOption<ProjectShopResource> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true,
    emptyText: 'billing.selectProjectFirst'
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<ProjectShopResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'shopCode',
      label: 'customerShop.code',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'shopName',
      label: 'customerShop.name',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'customerShop.address',
      showOverflowTooltip: true,
      minWidth: '280px',
      formatter: (row: Object): string => {
        return `
        ${(row as ProjectShopResource).province}
        ${(row as ProjectShopResource).city ? (row as ProjectShopResource).city : ''}
        ${(row as ProjectShopResource).district ? (row as ProjectShopResource).district : ''}
        ${(row as ProjectShopResource).address}`;
      }
    },
    {
      prop: 'projectCode',
      label: 'project.code',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Select',
      field: 'projectId',
      label: 'billing.ownerProject',
      option: {
        placeholder: 'billing.selectOwnerProject',
        clearable: false,
        filterable: true
      },
      optionData: [],
      colSpan: 8
    },
    {
      type: 'Input',
      field: 'shopName',
      label: 'projectProduct.shopName',
      option: {
        placeholder: 'projectShop.inputShopName'
      },
      colSpan: 8
    },
    {
      type: 'Input',
      field: 'shopCode',
      label: 'customerShop.code',
      option: {
        placeholder: 'projectShop.inputShopCode'
      },
      colSpan: 8
    },
    {
      type: 'Slot',
      slotName: 'address',
      field: 'area',
      label: 'customer.area',
      option: {},
      colSpan: 8
    }
  ];

  public selectedRows: Array<ProjectShopResource> = [];

  public submitLoading = false;
  public queryForm: Partial<ProjectShopQuery> = {
    projectId: undefined,
    address: []
  };

  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private projects: Array<{ projectCode: string; projectName: string; id: number }> = [];

  @InjectReactive()
  private readonly customerId!: number;

  public dialogOpen(): void {
    this.getProjects();
  }

  public dialogClosed(): void {
    this.clearSelection();
  }

  public queryClick(): void {
    if (!this.queryForm.projectId) {
      Message.warning(translation('billing.selectProjectFirst'));
      return;
    }
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.getCustomerShop();
  }

  public handlePaging(): void {
    this.getCustomerShop();
  }

  public handleSelectionChange(selectedValues: Array<ProjectShopResource>): void {
    this.selectedRows = [...selectedValues];
  }

  public onSubmit(): void {
    const projectName = this.projects.find(x => x.id === this.queryForm.projectId)?.projectName;
    this.selectedRows.forEach((x: any) => {
      x.projectName = projectName;
    });
    this.$emit('select-confirm', this.selectedRows);
    this.syncedVisible = false;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    this.selectedRows = [];
    (this.$refs.customerShopTable as OsTable).clearSelection();
  }

  private getCustomerShop(): void {
    if (!this.queryForm.projectId) {
      Message.warning(translation('billing.selectProjectFirst'));
      return;
    }
    this.tableOption.loading = true;
    const queryCondition = {
      ...this.queryForm,
      ...convertProvinces(this.queryForm.address || [])
    };

    projectShopService
      .getProjectShopList(queryCondition, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.pageTotal = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getProjects(): void {
    billingService
      .getProjectsByCustomer(this.customerId)
      .then(res => {
        this.projects = res;
        const queryIndex = this.queryItemsOption.findIndex(x => x.field === 'projectId')!;
        this.queryItemsOption[queryIndex].optionData = res.map(x => {
          return {
            label: `[${x.projectCode}] ${x.projectName}`,
            value: x.id
          };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }
}
