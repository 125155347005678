import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import {
  BatchEditPriceForm,
  BatchSetDiscountForm,
  CustomerProductResource,
  CustomerProjectList,
  OtherRequestList,
  ProductRequestList
} from '@/resource/model';
import { messageError, showWarningConfirm, translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, PropSync, Watch } from 'vue-property-decorator';
import { SelectCustomerProduct } from '@/views/dialogs/index';
import BatchEditPrice from '../batch-edit-price/batch-edit-price.vue';
import { Message } from 'element-ui';
import { billingService } from '@/api';
import { CalculatePriceMixin } from '../calculate-price-mixin';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { Decimal } from 'decimal.js';
import SelectCustomerProject from './select-customer-project/select-customer-project.vue';
import BatchSetDiscount from '../batch-set-discount/batch-set-discount.vue';

@Component({
  components: { SelectCustomerProduct, BatchEditPrice, SelectCustomerProject, BatchSetDiscount }
})
export default class Other extends mixins(PagingMixin, CalculatePriceMixin, CustomColumnMixin) {
  @PropSync('otherDetails', {
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  public tableData!: Array<Partial<OtherRequestList>>;

  @InjectReactive()
  public readonly customerId!: number;

  public tableOptions: OsTableOption<Partial<OtherRequestList>> = {
    loading: false,
    data: [],
    fit: true,
    rowKey: () => {
      return 'itemId';
    },
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'count',
        fixPlace: 0
      },
      {
        prop: 'discountAmount',
        fixPlace: 2
      },
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      }
      // {
      //   prop: 'taxAmount',
      //   fixPlace: 2
      // },
      // {
      //   prop: 'amountIncludingTax',
      //   fixPlace: 2
      // }
    ]
  };

  public defaultColumnOptions: Array<OsTableColumn<OtherRequestList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true,
      width: '58px'
    },
    {
      type: 'index',
      prop: 'itemId',
      label: 'common.index',
      width: '80px'
    },
    {
      prop: 'itemCode',
      label: 'product.code',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'itemName',
      label: 'product.name',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'billing.ownerProject',
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'billing.priceCount',
      minWidth: '160px'
    },
    {
      prop: 'priceBeforeTax',
      label: 'billing.priceBeforeTax',
      minWidth: '160px'
    },
    {
      prop: 'priceIncludingTax',
      label: 'billing.priceIncludingTax',
      minWidth: '160px'
    },
    {
      prop: 'discountRate',
      label: 'billing.discountRate',
      minWidth: '160px'
    },
    {
      prop: 'discountAmount',
      label: 'billing.discountAmount',
      minWidth: '160px'
    },
    {
      prop: 'discountRemark',
      label: 'billing.discountRemark',
      minWidth: '160px'
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: '',
      handleClick: this.openSelectProductDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'billing.batchEditPrice',
      operationType: 'edit',
      icon: 'el-icon-edit',
      permissionCode: '',
      disabled: true,
      handleClick: this.openBatchEditPriceDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'billing.batchSetDiscount',
      operationType: 'discount',
      icon: 'el-icon-edit',
      handleClick: this.openBatchSetDiscountDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'billing.roundDown',
      operationType: 'roundDown',
      icon: 'el-icon-edit',
      disabled: true,
      handleClick: this.handleRoundDownClick
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      permissionCode: 'finance:bill:deleteBillDetail',
      plain: true,
      disabled: true,
      handleClick: this.batchDelete
    }
  ];

  public selectedRows: Array<OtherRequestList> = [];

  public selectProductVisible = false;

  public selectProjectVisible = false;

  public batchEditPriceVisible = false;

  public batchSetDiscountVisible = false;

  private pendingData: OtherRequestList | null = null;

  private readonly canEditCells: Array<Partial<keyof OtherRequestList>> = [
    'count',
    'priceBeforeTax',
    'priceIncludingTax',
    'amountExcludingTax',
    // 'taxAmount',
    // 'taxRate',
    'discountAmount',
    'discountRate',
    'discountRemark',
    'remark'
  ];

  public get selectedAmountExcludingTax(): string {
    let res = new Decimal(0);
    this.selectedRows.forEach(x => {
      res = res.add(new Decimal(x.amountExcludingTax));
    });
    return res.toFixed(2);
  }

  @Watch("tableData")
  public watchTableData(): void {
    this.pagingData();
  }

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'billing-other');
    if (this.tableData.length > 0) {
      this.tableData.forEach(x => {
        this.initInputDynamicProp(x, this.canEditCells);
      });
      this.pagingData();
    }
  }

  public handleSelectionChange(selectedData: Array<OtherRequestList>): void {
    this.selectedRows = selectedData;
  }

  public pagingData(): void {
    this.totalData = this.tableData.length;
    this.tableOptions.data = this.tableData.slice(
      (this.paging.currentPage - 1) * this.paging.showCount,
      this.paging.currentPage * this.paging.showCount
    );
  }

  /**
   * 选择项次确认后的回调
   */
  public handleSelectedProjectItem(customerProduct: Array<CustomerProductResource>): void {
    // 去除已添加的
    const itemIds = this.tableData.map(x => x.itemId);
    const toAddDatas = customerProduct.filter(x => !itemIds.includes(x.id));

    this.tableData.unshift(
      ...toAddDatas.map(x => {
        // 初始化数据
        const item: Partial<ProductRequestList> = {
          priceBeforeTax: x.price,
          priceIncludingTax: x.price,
          itemId: x.id,
          itemCode: x.productCode,
          itemName: x.productName,
          count: 1,
          taxRate: 0,
          amountExcludingTax: 0,
          amountIncludingTax: 0,
          discountAmount: 0,
          discountRate: 0,
          discountRemark: '',
          taxAmount: 0,
          remark: '',
          projectId: undefined,
          projectName: ''
        };
        Object.assign(item, x);

        // 手动去除对象属性赋值带来的影响
        delete item.id;
        item.remark = '';

        // 初始化要展示的数据
        this.initInputDynamicProp(item, this.canEditCells);
        this.handlePriceBeforeTaxChange(item as ProductRequestList);
        return item;
      })
    );

    // 添加完产品项次后回到第一页
    this.paging.currentPage = 1;
    this.pagingData();
  }

  /**
   * 批量修改金额的回调
   * @param params 要批改的金额以及属性
   */
  public handleBatchEditPrice(params: BatchEditPriceForm): void {
    for (const item of this.selectedRows) {
      if (params.prop === 'priceBeforeTax') {
        item.priceBeforeTax = params.amount;
        this.handlePriceBeforeTaxChange(item);
        continue;
      }
      if (params.prop === 'priceIncludingTax') {
        item.priceIncludingTax = params.amount;
        this.handlePriceIncludingTaxChange(item);
        continue;
      }
      if (params.prop === 'amountExcludingTax') {
        item.amountExcludingTax = params.amount;
        this.handleAmountExcludingTaxChange(item);
        continue;
      }
    }
  }

  /**
   * 处理可编辑单元格的点击事件
   * @param prop 处理
   * @param rowData 当前行的数据
   */
  public handlePropClick(prop: string, rowData: { [P: string]: any }): void {
    rowData[`${prop}Input`] = true;
  }

  public openProjectDialog(rowData: OtherRequestList): void {
    this.selectProjectVisible = true;
    this.pendingData = rowData;
  }

  public handleProjectDialogConfirm(project: CustomerProjectList): void {
    if (!this.pendingData) {
      return;
    }
    this.pendingData.projectId = project.id;
    this.pendingData.projectName = project.projectName;
  }

  /**
   * 批量设置优惠确认
   */
  public handleDiscountConfirm(form: BatchSetDiscountForm): void {
    if (this.selectedRows.length > 0) {
      this.setDiscount(form, this.selectedRows);
      return;
    }
    this.setDiscount(form, this.tableData as Array<OtherRequestList>);
    this.pagingData();
  }

  @Watch('selectedRows')
  private handleSelectedChanged(values: Array<OtherRequestList>): void {
    const dynamicControlsBtnType = ['edit', 'roundDown', 'batchDelete'];
    this.operationOptions
      .filter(x => dynamicControlsBtnType.includes(x.operationType))
      .forEach(x => {
        x.disabled = values.length === 0;
      });
  }

  private batchDelete(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          // 对于尚未保存的明细，使用itemId即项次主键进行删除
          const itemIds = this.selectedRows.filter(x => !x.id).map(x => x.itemId);
          if (itemIds.length > 0) {
            const filterData = this.tableData.filter(x => !itemIds.includes(x.itemId!));
            // 对于prop.sync的数据只能使用vue包装过的数组处理方法，先清除再添加
            this.tableData.splice(0, this.tableData.length);
            this.tableData.push(...filterData);
          }

          // 对于已保存过的，需要调用接口进行删除
          const ids = this.selectedRows.map(x => x.id).filter(x => x);
          if (ids.length > 0) {
            await billingService.batchDeleteBillingDetails(ids as Array<number>);
            const filterData = this.tableData.filter(x => !ids.includes(x.id!));
            // 对于prop.sync的数据只能使用vue包装过的数组处理方法，先清除再添加
            this.tableData.splice(0, this.tableData.length);
            this.tableData.push(...filterData);
          }

          Message.success(translation('operationRes.deleteSuccess'));
          this.$emit('shop-deleted', this.selectedRows);
          this.clearSelection();
          this.pagingData();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private clearSelection(): void {
    // 处理勾选问题
    (this.$refs.otherTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private openSelectProductDialog(): void {
    this.selectProductVisible = true;
  }

  private openBatchEditPriceDialog(): void {
    this.batchEditPriceVisible = true;
  }

  private openBatchSetDiscountDialog(): void {
    this.batchSetDiscountVisible = true;
  }

  /**
   * 抹零操作
   */
  private handleRoundDownClick(): void {
    this.roundDown(this.selectedRows);
    this.clearSelection();
  }

  /**
   * 设置优惠
   * @param form
   * @param targetData
   */
  private setDiscount(form: BatchSetDiscountForm, targetData: Array<OtherRequestList>): void {
    if ((form.discountAmount && form.discountRate) || form.discountAmount) {
      targetData.forEach(x => {
        x.discountRemark = form.discountRemark;
        // 如果填写的优惠金额大于未税金额，将自动设置为全额优惠
        if (form.discountAmount > x.amountExcludingTax + x.discountAmount) {
          x.discountRate = 100;
          this.handleDiscountRateChange(x);
        } else {
          x.discountAmount = form.discountAmount;

          this.handleDiscountAmountChange(x);
        }
      });
    } else if (form.discountRate) {
      targetData.forEach(x => {
        x.discountRate = form.discountRate;
        x.discountRemark = form.discountRemark;
        this.handleDiscountRateChange(x);
      });
    }
  }
}
