import { CustomerInvoiceResource } from '@/resource/model';
import { Component, InjectReactive } from 'vue-property-decorator';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsTable } from '@/components';
import { fuzzyQueryStr, messageError, translation } from '@/utils';
import { customerInvoiceService } from '@/api';
import { mixins } from 'vue-class-component';
import { DialogMixin } from '@/mixins/dialog';
import { InvoiceTypeEnum } from '@/resource/enum';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';

@Component({
  components: {}
})
export default class SelectCustomerInvoice extends mixins(DialogMixin) {
  public tableOption: OsTableOption<CustomerInvoiceResource> = {
    loading: false,
    data: [],
    fit: true,
    radioSelection: true
  };
  public tableData!: Array<CustomerInvoiceResource>;
  /**
   * 过滤后的数据，在没有任何筛选条件生效时，该数据必须保证与tableData一致
   */
  public filteredData: Array<CustomerInvoiceResource> = [];
  /**
   * 表格列配置
   */
  public columnOptions: Array<OsTableColumn<CustomerInvoiceResource>> = [
    {
      prop: 'companyName',
      label: 'invoice.companyName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'taxCode',
      label: 'invoice.taxCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'invoiceType',
      label: 'invoice.invoiceType',
      minWidth: '120px',
      formatter: (rowData: object): string | undefined => {
        return translation(`invoiceType.${InvoiceTypeEnum[(rowData as CustomerInvoiceResource).invoiceType]}`);
      }
    },
    {
      prop: 'bankName',
      label: 'invoice.bankName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'bankAccount',
      label: 'invoice.bankAccount',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'address',
      label: 'invoice.address',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'contactNumber',
      label: 'invoice.contactNumber',
      showOverflowTooltip: true,
      minWidth: '150px'
    }
  ];
  public queryItemsOption: Array<OsQueryItemOption<CustomerInvoiceResource>> = [
    {
      type: 'Input',
      field: 'companyName',
      label: 'invoice.companyName',
      option: {
        placeholder: 'common.input'
      }
    }
  ];

  public queryForm: Partial<CustomerInvoiceResource> = {};

  public selectedRow: CustomerInvoiceResource | null = null;

  @InjectReactive()
  private readonly customerId!: number;

  public dialogOpen(): void {
    this.getInvoice();
  }

  public dialogClosed(): void {
    this.clearSelection();
    this.queryForm = {};
  }

  public handleRadioSelectionChange(selectedValue: CustomerInvoiceResource): void {
    this.selectedRow = selectedValue;
  }

  public onSubmit(): void {
    this.$emit('confirm', this.selectedRow);
    this.closeDialog();
  }

  public get radioConfirmDisabled(): boolean {
    return !this.selectedRow;
  }
  /**
   * 同步filteredData 和 tableData的数据
   */
  public synchronizationData(): void {
    this.filteredData = this.tableData;
  }

  public filterData(): void {
    this.synchronizationData();
    if (this.queryForm.companyName) {
      this.filteredData = this.filteredData.filter(x => fuzzyQueryStr(this.queryForm.companyName!, x.companyName!));
    }
    this.tableOption.data = [];
    this.tableOption.data.push(...this.filteredData);
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    this.selectedRow = null;
    (this.$refs.customerInvoiceTable as OsTable).clearRadioSelection();
  }

  private getInvoice(): void {
    this.tableOption.loading = true;
    customerInvoiceService
      .getAllInvoices(this.customerId)
      .then(res => {
        this.tableData = res;
        this.filterData();
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
