import { billingService, projectProductService } from '@/api';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { PagingMixin } from '@/mixins/paging';
import { ProjectItemTypeEnum } from '@/resource/enum';
import { InviceProjectProductItems, QueryInviceProjectProductItems } from '@/resource/model';
import { dateFormat, messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SelectProjectItem extends mixins(PagingMixin, DialogMixin) {
  public tableOptions: OsTableOption<InviceProjectProductItems> = {
    loading: false,
    data: [],
    fit: true,
    height: '500px',
    closeAdaptiveHeight: true,
    emptyText: 'billing.selectProjectFirst',
    rowKey: () => {
      return 'itemId';
    }
  };

  public columnOptions: Array<OsTableColumn<InviceProjectProductItems>> = [
    {
      type: 'selection',
      prop: 'itemId',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'project.name',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'itemType',
      label: 'projectProduct.itemType',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        const itemType = (row as InviceProjectProductItems).itemType;
        return translation(`projectItemType.${ProjectItemTypeEnum[itemType]}`);
      }
    },
    {
      prop: 'attritionFlag',
      label: 'projectProduct.isRemake',
      minWidth: '100px',
      formatter: (rowData: Object): string => {
        return (rowData as InviceProjectProductItems).attritionFlag === 1
          ? translation('common.yes')
          : translation('common.no');
      }
    },
    {
      prop: 'areaPriceFlag',
      label: 'orderProduct.areaPriceFlag',
      minWidth: '100px',
      formatter: (rowData: Object): string => {
        return (rowData as InviceProjectProductItems).areaPriceFlag === 1
          ? translation('common.yes')
          : translation('common.no');
      }
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'itemName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'priceUnitName',
      label: 'projectProduct.priceUnitName',
      minWidth: '130px',
      showOverflowTooltip: true
    },
    {
      prop: 'priceBeforeTax',
      label: 'projectProduct.salePrice',
      minWidth: '130px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as InviceProjectProductItems).priceBeforeTax?.toFixed(6);
      }
    },
    {
      prop: 'saleCount',
      label: 'projectProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as InviceProjectProductItems).finishWidth} × ${(row as InviceProjectProductItems).finishHeight}`;
      }
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as InviceProjectProductItems).createTime);
      }
    }
  ];

  public queryItemsOption: Array<OsQueryItemOption<QueryInviceProjectProductItems>> = [
    {
      type: 'Select',
      field: 'projectIdList',
      label: 'billing.ownerProject',
      option: {
        placeholder: 'billing.selectOwnerProject',
        clearable: true,
        filterable: true,
        multiple: true
      },
      colSpan: 8,
      optionData: []
    },
    {
      type: 'Input',
      field: 'itemCode',
      label: 'projectProduct.itemCode',
      colSpan: 8,
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'itemType',
      label: 'projectProduct.itemType',
      option: {
        placeholder: 'projectProduct.selectItemType'
      },
      colSpan: 8,
      optionData: projectProductService.getProjectItemType
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'projectProduct.platformProduct',
      colSpan: 8,
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'customerProductName',
      label: 'projectProduct.customerProduct',
      colSpan: 8,
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'attritionFlag',
      label: 'projectProduct.isRemake',
      colSpan: 8,
      option: {
        placeholder: 'common.select'
      },
      optionData: billingService.getIsRedoSelect
    },
    {
      type: 'Input',
      field: 'pointName',
      label: 'projectProduct.pointName',
      colSpan: 8,
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'common.createUserName',
      colSpan: 8,
      option: {
        placeholder: 'common.inputCreateUserName',
        clearable: true
      }
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'deliveryNoticeList.orderCreateTime',
      colSpan: 8,
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  public selectedRows: Array<InviceProjectProductItems> = [];

  private queryForm: Partial<QueryInviceProjectProductItems> = {};

  @InjectReactive()
  private readonly customerId!: number;

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.totalData})`;
  }

  public dialogOpen(): void {
    this.getProjects();
  }

  public dialogClosed(): void {
    this.queryForm = {};
    this.tableOptions.data = [];
    this.totalData = 0;
    this.paging.showCount = 50;
    this.clearSelection();
  }

  public handleSelectionChange(selectedData: Array<InviceProjectProductItems>): void {
    this.selectedRows = selectedData;
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.getProjectItem();
  }

  public pagingData(): void {
    this.getProjectItem();
  }

  public getProjectItem(): void {
    if (this.queryForm.projectIdList?.length === 0 || !this.queryForm.projectIdList) {
      Message.warning(translation('billing.selectProjectFirst'));
      return;
    }
    this.tableOptions.loading = true;
    billingService
      .getInvoiceProjectProductItems(this.queryForm, this.paging)
      .then(res => {
        this.tableOptions.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOptions.loading = false;
      });
  }

  public onSubmit(): void {
    this.submitLoading = true;
    this.$nextTick(() => {
      this.$emit('select-confirm', this.selectedRows);
      this.submitLoading = false;
      this.closeDialog();
    });
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  private getProjects(): void {
    billingService
      .getProjectsByCustomer(this.customerId)
      .then(res => {
        const queryIndex = this.queryItemsOption.findIndex(x => x.field === 'projectIdList')!;
        this.queryItemsOption[queryIndex].optionData = res.map(x => {
          return {
            label: `[${x.projectCode}] ${x.projectName}`,
            value: x.id
          };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  private clearSelection(): void {
    this.selectedRows = [];
    (this.$refs.projectProductTable as OsTable).clearSelection();
  }
}
