import { billingService, invoiceService, taxRateService } from '@/api';

// import { OsFileUploadOptions } from '@/components/os-file-upload/os-file-upload';
import { DialogMixin } from '@/mixins/dialog';
import { ChargeUnitEnum, InvoiceColorEnum, InvoiceModeEnum, InvoiceTypeEnum } from '@/resource/enum';
import {
  BillInvoiceDetailRequestList,
  BillInvoiceRequestList,
  InstallAttachmentsList,
  InvoiceResource,
  TaxRateResource
} from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
// import { UserModule } from '@/store/modules/user';
import { decimalToNumber2, decimalToNumber6, messageError, showWarningConfirm, translation } from '@/utils';
import { ElForm } from 'element-ui/types/form';
// import { HttpRequestOptions } from 'element-ui/types/upload';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, Watch, Vue, Prop } from 'vue-property-decorator';
import SelectInvoice from './select-invoice/select-invoice.vue';
import SelectCustomerInvoice from './select-customer-invoice/select-customer-invoice.vue';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { cloneDeep, uniqueId } from 'lodash-es';
import { Message, Notification } from 'element-ui';
import { telOrMobile } from '@/utils/validate';
import { SelectUnitMeasure } from '@/views/dialogs';
import Decimal from 'decimal.js';
import UploadAttachment from '@/views/finance-management/components/upload-attachment/upload-attachment.vue';
@Component({
  components: { SelectInvoice, SelectCustomerInvoice, SelectUnitMeasure, UploadAttachment }
})
export default class AddInvoice extends mixins(DialogMixin) {
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return null;
    }
  })
  public invoice!: BillInvoiceRequestList | null;
  public loading: boolean = false;
  public invoiceForm: Partial<BillInvoiceRequestList> = {
    color: InvoiceColorEnum.blue,
    invoiceMethod: InvoiceModeEnum.electronic,
    fileIdList: [],
    fileList: [],
    remark: '',
    companyName: '',
    taxNumber: '',
    bankName: '',
    bankNumber: '',
    tel: '',
    address: '',
    invoiceType: undefined,
    account: undefined,
    invoiceCompany: '',
    amountExcludingTax: 0,
    amountIncludingTax: 0,
    billInvoiceDetailRequestList: []
  };

  public formRules: { [P in keyof Partial<BillInvoiceRequestList>]: Array<object> } = {
    color: [
      {
        required: true,
        validator: (rule: any, value: InvoiceColorEnum, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectCustomer')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    invoiceMethod: [
      {
        required: true,
        validator: (rule: any, value: InvoiceModeEnum, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectInvoiceMode')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    companyName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectInvoiceTitle')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    taxNumber: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.inputTaxNumber')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    invoiceType: [
      {
        required: true,
        validator: (rule: any, value: InvoiceTypeEnum, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectInvoiceType')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    account: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.inputPaymentDay')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    invoiceCompany: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectInvoiceCompany')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    tel: [
      {
        required: false,
        validator: (rule: any, value: string | '', callback: Function): void => {
          if (value && !telOrMobile(value)) {
            callback(new Error(translation('common.inputLegalTel')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  // public detailUploadOptions: OsFileUploadOptions = {
  //   accept: '.pdf,.xlsx,.xls,.png,.jpg,.jpeg,.zip',
  //   autoUpload: true,
  //   limit: 5,
  //   multiple: true,
  //   drag: false,
  //   action: '',
  //   uploadLoading: false,
  //   fileList: [],
  //   onRemove: this.onRemove,

  //   httpRequest: this.uploadAttachment
  // };

  public colors: NormalSelectOptions = billingService.getInvoiceColors();

  public invoiceModes: NormalSelectOptions = billingService.getInvoiceModes();

  public invoiceTypes: NormalSelectOptions = billingService.getInvoiceTypes();

  public selectInvoiceDialog = false;

  public selectCustomerInvoiceDialog = false;

  public selectUnitDialog = false;
  public tableOptions: OsTableOption<Partial<BillInvoiceDetailRequestList>> = {
    loading: false,
    data: [],
    fit: true,
    rowKey: () => {
      return 'uuid';
    },
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'count',
        fixPlace: 0
      },
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      },
      {
        prop: 'amountIncludingTax',
        fixPlace: 2
      },
      {
        prop: 'taxAmount',
        fixPlace: 2
      }
    ]
  };

  public columnOptions: Array<OsTableColumn<BillInvoiceDetailRequestList>> = [
    {
      type: 'selection',
      prop: 'uuid',
      label: '',
      fixed: true,
      width: '58px'
    },
    {
      type: 'index',
      prop: 'id',
      label: 'common.index',
      width: '80px'
    },
    {
      prop: 'taxRevenueName',
      label: 'billing.taxRevenueName',
      minWidth: '180px'
    },
    {
      prop: 'serviceName',
      label: 'billing.serviceName',
      minWidth: '180px'
    },
    {
      prop: 'priceUnit',
      label: 'billing.priceUnit',
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'billing.priceCount',
      minWidth: '180px'
    },
    {
      prop: 'priceBeforeTax',
      label: 'billing.priceBeforeTax',
      minWidth: '180px'
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px'
    },
    {
      prop: 'taxRate',
      label: 'billing.taxRate',
      minWidth: '160px'
    },
    {
      prop: 'taxAmount',
      label: 'billing.taxAmount',
      minWidth: '160px',
      formatter: (row: object): number => {
        return decimalToNumber2(new Decimal((row as BillInvoiceDetailRequestList).taxAmount));
      }
    },
    {
      prop: 'amountIncludingTax',
      label: 'billing.amountIncludingTax',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: '',
      handleClick: this.addDataRow
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.copy',
      operationType: 'copy',
      icon: 'el-icon-document-copy',
      permissionCode: '',
      disabled: true,
      handleClick: this.copyInvoice
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      permissionCode: 'finance:bill:deleteInvoiceDetail',
      plain: true,
      disabled: true,
      handleClick: this.batchDelete
    }
  ];

  public selectedRows: Array<BillInvoiceDetailRequestList> = [];

  public editRow: BillInvoiceDetailRequestList | null = null;

  public deleteIds: Array<number | undefined> = [];

  public taxRates: Array<TaxRateResource> = [];

  public platformInvoices: Array<InvoiceResource> = [];

  private get fileList(): Array<InstallAttachmentsList> {
    return this.invoiceForm.fileList!;
  }

  private readonly defaultInvoiceProps: Partial<BillInvoiceDetailRequestList> = {
    uuid: '',
    serviceName: '',
    taxRevenueName: '',
    amountExcludingTax: 0,
    amountIncludingTax: 0,
    count: 1,
    priceBeforeTax: 0,
    priceUnit: undefined,
    priceUnitName: '',
    taxAmount: 0,
    taxRate: 0
  };

  private readonly canEditCells: Array<Partial<keyof BillInvoiceDetailRequestList>> = [
    'count',
    'priceBeforeTax',
    'amountExcludingTax',
    'taxRate',
    'taxAmount',
    'amountIncludingTax',
    'taxRevenueName',
    'priceUnit',
    'serviceName'
  ];

  @InjectReactive()
  private readonly customerId!: number;

  @InjectReactive()
  private readonly customerName!: string;

  public created(): void {
    this.getplaftormInvoice();
    if (this.invoice) {
      Object.assign(this.invoiceForm, this.invoice);
      // if (this.invoiceForm.file) {
      //   this.detailUploadOptions.fileList = [{ name: getMinioFileName(this.invoiceForm.file), url: '' }];
      // }
      this.tableOptions.data = this.invoiceForm.billInvoiceDetailRequestList || [];
      this.tableOptions.data.forEach(x => {
        this.initInputDynamicProp(x);
      });
    }
  }

  public destroyed(): void {
    this.$emit('dialog-closed');
  }

  public onSubmit(): void {
    (this.$refs.invoiceForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      // 删除后端已保存的数据
      if (this.deleteIds.length > 0) {
        await billingService.batchDeleteInvoiceDetail(this.deleteIds as Array<number>);
        const filterData = this.tableOptions.data.filter(x => !this.deleteIds.includes(x.id!));
        // 对于prop.sync的数据只能使用vue包装过的数组处理方法，先清除再添加
        this.tableOptions.data.splice(0, this.tableOptions.data.length);
        this.tableOptions.data.push(...filterData);
      }

      let errorMsg = '';
      // 先置空，然后计算总额
      this.invoiceForm.amountExcludingTax = 0;
      this.invoiceForm.amountIncludingTax = 0;
      this.invoiceForm.taxAmount = 0;
      if (this.tableOptions.data.length === 0) {
        errorMsg += `<br />未填写发票明细！<br />`;
      }

      this.tableOptions.data.forEach((x, index) => {
        if (!x.serviceName) {
          errorMsg += `<br />序号为${index + 1}的 “服务名称” 不能为空！<br />`;
        }
        if (!x.count) {
          errorMsg += `序号为${index + 1}的 “计价数量” 不能为空！<br />`;
        }
        if (!x.priceBeforeTax) {
          errorMsg += `序号为${index + 1}的 “未税单价” 不能为空！<br />`;
        }
        if (!x.amountExcludingTax) {
          errorMsg += `序号为${index + 1}的 “未税金额” 不能为空！<br />`;
        }
        if (x.taxRate !== 0 && !x.taxRate) {
          errorMsg += `序号为${index + 1}的 “税率” 不能为空！<br />`;
        }
        if (!x.amountIncludingTax) {
          errorMsg += `序号为${index + 1}的 “含税金额” 不能为空！<br />`;
        }
        if (errorMsg) {
          return;
        }

        this.invoiceForm.amountExcludingTax = new Decimal(this.invoiceForm.amountExcludingTax!)
          .add(new Decimal(x.amountExcludingTax!))
          .toNumber();

        this.invoiceForm.amountIncludingTax = new Decimal(this.invoiceForm.amountIncludingTax!)
          .add(new Decimal(x.amountIncludingTax!))
          .toNumber();

        this.invoiceForm.taxAmount = new Decimal(this.invoiceForm.taxAmount!).add(new Decimal(x.taxAmount!)).toNumber();
      });

      // 纸票限制未税金额
      if (this.invoiceForm.invoiceMethod === InvoiceModeEnum.paper) {
        if (this.invoiceForm.amountExcludingTax >= 100000) {
          Message.warning(translation('billing.amountExcludingTaxExceed'));
          return;
        }
      }

      if (errorMsg) {
        Notification.error({
          title: translation('dialog.saveFailed'),
          duration: 0,
          dangerouslyUseHTMLString: true,
          message: `<span>${errorMsg}</span><br />`
        });
        return;
      }
      this.invoiceForm.billInvoiceDetailRequestList = cloneDeep(this.tableOptions.data) as any;
      this.invoice ? this.$emit('edit-success', this.invoiceForm) : this.$emit('add-success', this.invoiceForm);
      this.closeDialog();
    });
  }

  public openDialog(): void {
    this.deleteIds = [];
  }

  public syncFileId(data: Array<InstallAttachmentsList>): void {
    this.invoiceForm.fileIdList = data.map(x => x.id) || [];
    this.invoiceForm.fileList = [...data];
  }

  public openSelectInvoiceDialog(): void {
    this.selectInvoiceDialog = true;
  }

  public openSelectCustomerInvoiceDialog(): void {
    this.selectCustomerInvoiceDialog = true;
  }

  public handleSelectedInvoice(invoice: InvoiceResource): void {
    this.invoiceForm.invoiceCompany = invoice.companyName;
    this.getTaxRates(invoice.id);
  }
  public openSelectUnitDialog(rowData: any): void {
    this.editRow = rowData;
    this.selectUnitDialog = true;
  }

  public handleSelectedUnit(rowData: any): void {
    this.tableOptions.data.forEach(x => {
      if (x.uuid === this.editRow!.uuid) {
        Vue.set(x, `priceUnit`, rowData.id);
        Vue.set(x, `priceUnitName`, rowData.name);
      }
    });
    this.editRow = null;
  }

  public handleSelectedCustomerInvoice(customerInvoice: InvoiceResource): void {
    this.invoiceForm.bankName = customerInvoice.bankName;
    this.invoiceForm.invoiceType = customerInvoice.invoiceType;
    this.invoiceForm.taxNumber = customerInvoice.taxCode;
    this.invoiceForm.address = customerInvoice.address;
    this.invoiceForm.tel = customerInvoice.contactNumber;
    this.invoiceForm.bankNumber = customerInvoice.bankAccount;
    this.invoiceForm.companyName = customerInvoice.companyName;
    (this.$refs.invoiceForm as ElForm).validate();
  }

  public handleInvoiceSelectionChange(selectedData: Array<BillInvoiceDetailRequestList>): void {
    this.selectedRows = selectedData;
  }

  /**
   * 处理可编辑单元格的点击事件
   * @param prop 处理
   * @param rowData 当前行的数据
   */
  public handlePropClick(prop: string, rowData: { [P: string]: any }): void {
    rowData[`${prop}Input`] = true;
  }

  public handleTaxRevenueNameBlur(rowData: BillInvoiceDetailRequestList & { taxRevenueNameInput: boolean }): void {
    setTimeout(() => {
      rowData.taxRevenueNameInput = false;
    }, 100);
  }

  public handleTaxRevenueNameChange(rowData: BillInvoiceDetailRequestList): void {
    const findTaxRate = this.taxRates.find(x => x.categoryName === rowData.taxRevenueName)!;
    rowData.taxRate = findTaxRate.taxRate;
    this.handleTaxRateChange(rowData);
  }

  public handlePriceUnitBlur(rowData: BillInvoiceDetailRequestList & { priceUnitInput: boolean }): void {
    setTimeout(() => {
      rowData.priceUnitInput = false;
    }, 300);
  }

  public getPriceUnitName(priceUnit: ChargeUnitEnum): string {
    return priceUnit ? translation(`chargeUnit.${ChargeUnitEnum[priceUnit]}`) : '';
  }

  /**
   * 计价数量发生变化
   * @param rowData
   */
  public handleCountChange(rowData: BillInvoiceDetailRequestList): void {
    // 未税金额 = 未税单价 * 计价数量
    rowData.amountExcludingTax = decimalToNumber2(new Decimal(rowData.priceBeforeTax).mul(new Decimal(rowData.count)));

    // 计算税额 = 未税金额 * 税率
    rowData.taxAmount = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).mul(new Decimal(rowData.taxRate).div(100))
    );

    // 含税金额 = 未税金额 + 税额
    rowData.amountIncludingTax = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.taxAmount))
    );
  }

  /**
   *  未税单价发生变化
   * @param rowData
   */
  public handlePriceBeforeTaxChange(rowData: BillInvoiceDetailRequestList): void {
    // 计算未税金额 = 未税单价 * 计价数量
    rowData.amountExcludingTax = decimalToNumber2(new Decimal(rowData.priceBeforeTax).mul(new Decimal(rowData.count)));

    // 计算税额 = 未税金额 * 税率
    rowData.taxAmount = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).mul(new Decimal(rowData.taxRate).div(100))
    );

    // 含税金额 = 未税金额 + 税额
    rowData.amountIncludingTax = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.taxAmount))
    );
  }

  /**
   * 税率发生变化
   * @param rowData
   */
  public handleTaxRateChange(rowData: BillInvoiceDetailRequestList): void {
    // 计算税额 = 未税金额 * 税率
    rowData.taxAmount = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).mul(new Decimal(rowData.taxRate).div(100))
    );

    // 含税金额 = 未税金额 + 税额
    rowData.amountIncludingTax = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.taxAmount))
    );
  }

  /**
   * 未税金额发生变化
   * @param rowData
   */
  public handleAmountExcludingTaxChange(rowData: BillInvoiceDetailRequestList): void {
    // 计算税额 = 未税金额 * 税率
    rowData.taxAmount = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).mul(new Decimal(rowData.taxRate).div(100))
    );
    // 含税金额 = 未税金额 + 税额
    rowData.amountIncludingTax = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.taxAmount))
    );

    // 未税单价 = 未税金额 / 计价数量
    rowData.priceBeforeTax = decimalToNumber6(new Decimal(rowData.amountExcludingTax).div(new Decimal(rowData.count)));
  }

  /**
   * 含税金额发生变化
   * @param rowData
   */
  public handleAmountIncludingTaxChange(rowData: BillInvoiceDetailRequestList): void {
    // 未税单价 = 未税金额 / 计价数量
    rowData.priceBeforeTax = decimalToNumber6(
      new Decimal(rowData.amountIncludingTax)
        .div(new Decimal(rowData.taxRate).div(100).add(1))
        .div(new Decimal(rowData.count))
    );

    // 未税金额 = 未税单价 * 数量
    rowData.amountExcludingTax = decimalToNumber2(new Decimal(rowData.priceBeforeTax).mul(new Decimal(rowData.count)));

    // 税额 = 未税金额 * 税率
    rowData.taxAmount = decimalToNumber2(
      new Decimal(rowData.priceBeforeTax).mul(new Decimal(rowData.count)).mul(new Decimal(rowData.taxRate).div(100))
    );
  }
  public resetTableDataUUID(): void {
    this.tableOptions.data.forEach(x => {
      x.uuid = this.createUuid();
    });
  }

  // /**
  //  * 上传附件
  //  * @param requestOptions
  //  */
  // private async uploadAttachment(requestOptions: HttpRequestOptions): Promise<void> {
  //   try {
  //     this.detailUploadOptions.uploadLoading = true;
  //     this.loading = true;
  //     const minioFile: MinIOFile = {
  //       name: `${dateFormat(new Date(), 'yyyyMMDDHHmmss')}_${requestOptions.file.name}`,
  //       stream: Buffer.from(await requestOptions.file.arrayBuffer()),
  //       metadata: { 'Content-Type': requestOptions.file.type }
  //     };
  //     await minioService.init();
  //     const filePath = `platform/upload/${UserModule.account}/invoice`;
  //     await minioService.uploadByStream(minioFile, filePath);
  //     const path = `${filePath}/${minioFile.name}`;
  //     this.invoiceForm.file = path;
  //     this.detailUploadOptions.uploadLoading = false;
  //     this.loading = false;
  //     Message.success(translation('operationRes.uploadSucess'));
  //   } catch (error) {
  //     Message.success(translation('operationRes.uploadFailed'));
  //     this.detailUploadOptions.uploadLoading = false;
  //     this.loading = false;
  //     (this.$refs['detailUpload'] as OsFileUpload).clearFiles();
  //   }
  // }
  // private onRemove(): void {
  //   this.invoiceForm.file = '';
  // }

  private addDataRow(): void {
    const dataRow = cloneDeep(this.defaultInvoiceProps);
    dataRow.uuid = this.createUuid();
    if (this.taxRates?.length > 0) {
      dataRow.taxRevenueName = this.taxRates[0].categoryName;
      dataRow.taxRate = this.taxRates[0].taxRate;
    }
    this.initInputDynamicProp(dataRow);
    this.tableOptions.data.push(dataRow);
  }

  private copyInvoice(): void {
    const copyRows = cloneDeep(this.selectedRows);
    copyRows.forEach(x => {
      x.uuid = this.createUuid();
      delete x.id;
      this.initInputDynamicProp(x);
    });
    this.tableOptions.data.push(...copyRows);
    this.clearSelection();
  }

  private clearSelection(): void {
    (this.$refs.invoiceDetailsTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private batchDelete(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          // 对于尚未保存的明细，使用uuids进行删除
          const uuids = this.selectedRows.filter(x => !x.id).map(x => x.uuid);
          if (uuids.length > 0) {
            const filterData = this.tableOptions.data.filter(x => !uuids.includes(x.uuid!));
            // 对于prop.sync的数据只能使用vue包装过的数组处理方法，先清除再添加
            this.tableOptions.data.splice(0, this.tableOptions.data.length);
            this.tableOptions.data.push(...filterData);
          }

          const ids = this.selectedRows.map(x => x.id).filter(x => x);
          if (ids.length > 0) {
            this.deleteIds = [...this.deleteIds, ...ids].filter(x => x);
            const filterData = this.tableOptions.data.filter(x => !ids.includes(x.id!));
            // 对于prop.sync的数据只能使用vue包装过的数组处理方法，先清除再添加
            this.tableOptions.data.splice(0, this.tableOptions.data.length);
            this.tableOptions.data.push(...filterData);
          }

          Message.success(translation('operationRes.deleteSuccess'));
          this.$emit('shop-deleted', this.selectedRows);
          this.clearSelection();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private createUuid(): string {
    return uniqueId('invoice-details');
  }

  /**
   * 获取税收分类
   * @param invoiceId 平台端开票id
   */
  private getTaxRates(invoiceId: number): void {
    taxRateService
      .getAllTaxRates(invoiceId)
      .then(res => {
        this.taxRates = res;
        // 重新获取税收分类后，只清空已选的税收分类，但不用清除相关联的税率
        // this.tableOptions.data.forEach(x => {
        //   x.taxRevenueName = '';
        // });
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<BillInvoiceDetailRequestList>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add') x.disabled = value.length === 0;
    });
  }
  @Watch('tableOptions.data', { immediate: true })
  private watchTableData(): void {
    this.resetTableDataUUID();
  }

  /**
   * 初始化项次内，部分可以动态使用input编辑的属性
   * @param rowObj行对象
   * @param canEditCells 要初始化的列
   */
  private initInputDynamicProp(rowObj: any): void {
    this.canEditCells.forEach(x => {
      Vue.set(rowObj, `${x}Input`, false);
    });
  }

  private getplaftormInvoice(): void {
    invoiceService
      .getAllInvoices()
      .then(res => {
        this.platformInvoices.push(...res);
        if (!this.invoice) {
          return;
        }
        // 如果是编辑，还需要根据开票公司，拿到开票公司对应的税收分类
        const findInvice = this.platformInvoices.find(x => x.companyName === this.invoiceForm.invoiceCompany);
        if (findInvice) {
          this.getTaxRates(findInvice.id);
        }
      })
      .catch(error => {
        messageError(error);
      });
  }
}
