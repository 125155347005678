import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { BillingItemTypeEnum } from '@/resource/enum';
import {
  BatchEditPriceForm,
  BatchSetDiscountForm,
  ImportRes,
  InviceProjectProductItems,
  ProductRequestList,
  ProductRequestQuery
} from '@/resource/model';
import {
  decimalToNumber2,
  decimalToNumber6,
  downloadFileByBlob,
  fuzzyQueryStr,
  handleImportError,
  isNullOrUndefinedForBaseType,
  messageError,
  showWarningConfirm,
  translation
} from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, PropSync, Watch } from 'vue-property-decorator';
import SelectProjectItem from '../select-project-item/select-project-item.vue';
import BatchEditPrice from '../batch-edit-price/batch-edit-price.vue';
import { Message } from 'element-ui';
import { billingService, unitsMeasureService } from '@/api';
import { CalculatePriceMixin } from './calculate-price-mixin';
import { CustomColumnMixin } from '@/mixins/custom-column';
import Decimal from 'decimal.js';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { SortOptions } from '@/api/base';
import BatchSetDiscount from '../batch-set-discount/batch-set-discount.vue';
import { ImportFile } from '@/views/dialogs';
import { ApiResponse } from '@/api/axios';
import { NormalSelectOptions } from '@/resource/model/common';

@Component({
  components: { SelectProjectItem, BatchEditPrice, BatchSetDiscount, ImportFile }
})
export default class Make extends mixins(PagingMixin, CalculatePriceMixin, CustomColumnMixin) {
  @PropSync('makeDetails', {
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  public tableData!: Array<Partial<ProductRequestList>>;

  /**
   * 过滤后的数据，在没有任何筛选条件生效时，该数据必须保证与tableData一致
   */
  public filteredData: Array<Partial<ProductRequestList>> = [];

  public id = Number(this.$route.query.id);

  public tableOptions: OsTableOption<Partial<ProductRequestList>> = {
    loading: false,
    data: [],
    fit: true,
    rowKey: () => {
      return 'itemId';
    },
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'count',
        fixPlace: 2
      },
      {
        prop: 'discountAmount',
        fixPlace: 2
      },
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      }
      // {
      //   prop: 'taxAmount',
      //   fixPlace: 2
      // },
      // {
      //   prop: 'amountIncludingTax',
      //   fixPlace: 2
      // }
    ],
    defaultSort: { order: 'ascending', prop: 'itemCode' }
  };

  public defaultColumnOptions: Array<OsTableColumn<ProductRequestList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true,
      width: '58px'
    },
    {
      prop: 'index',
      label: 'common.index',
      width: '50px',
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'product.code',
      minWidth: '180px',
      showOverflowTooltip: true,
      sortable: 'custom'
    },
    {
      prop: 'itemName',
      label: 'product.name',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'project.name',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'priceUnitName',
      label: 'customerProduct.chargeUnit',
      minWidth: '150px',
      showOverflowTooltip: true
      // formatter: (rowData: Object): string => {
      //   const key = ChargeUnitEnum[(rowData as ProductRequestList).priceUnit]
      //     ? `chargeUnit.${ChargeUnitEnum[(rowData as ProductRequestList).priceUnit]}`
      //     : '';
      //   return key ? translation(key) : '--';
      // }
    },
    {
      prop: 'unitArea',
      label: 'billing.area',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'billing.paymentCount',
      minWidth: '160px'
    },
    {
      prop: 'settlementArea',
      label: 'billing.priceCount',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'priceBeforeTax',
      label: 'billing.priceBeforeTax',
      minWidth: '160px'
    },
    {
      prop: 'priceIncludingTax',
      label: 'billing.priceIncludingTax',
      minWidth: '160px'
    },
    // {
    //   prop: 'taxRate',
    //   label: 'billing.taxRate',
    //   minWidth: '160px'
    // },
    {
      prop: 'discountRate',
      label: 'billing.discountRate',
      minWidth: '160px'
    },
    {
      prop: 'discountAmount',
      label: 'billing.discountAmount',
      minWidth: '160px'
    },
    {
      prop: 'discountRemark',
      label: 'billing.discountRemark',
      minWidth: '160px'
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px'
    },
    // {
    //   prop: 'taxAmount',
    //   label: 'billing.taxAmount',
    //   minWidth: '160px'
    // },

    // {
    //   prop: 'amountIncludingTax',
    //   label: 'billing.amountIncludingTax',
    //   minWidth: '180px',
    //   formatter: (rowData: Object): string => {
    //     return ((rowData as ProductRequestList).amountIncludingTax ?? 0).toFixed(2);
    //   }
    // },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'saleUnitName',
      label: 'billing.saleUnit',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'saleCount',
      label: 'billing.saleCount',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ProductRequestList).finishWidth ?? '--'} × ${(row as ProductRequestList).finishHeight ??
          '--'}`;
      }
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'itemType',
      label: 'billing.itemType',
      minWidth: '120px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        const key = BillingItemTypeEnum[(rowData as ProductRequestList).itemType]
          ? `billingItemType.${BillingItemTypeEnum[(rowData as ProductRequestList).itemType]}`
          : '';
        return key ? translation(key) : '--';
      }
    },
    {
      prop: 'attritionFlag',
      label: 'projectProduct.isRemake',
      minWidth: '100px',
      formatter: (rowData: Object): string => {
        return (rowData as ProductRequestList).attritionFlag === 1
          ? translation('common.yes')
          : translation('common.no');
      }
    },
    {
      prop: 'areaPriceFlag',
      label: 'orderProduct.areaPriceFlag',
      minWidth: '100px',
      formatter: (rowData: Object): string => {
        return (rowData as ProductRequestList).areaPriceFlag === 1
          ? translation('common.yes')
          : translation('common.no');
      }
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: '',
      handleClick: this.openSelectProductDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'billing.batchEditPrice',
      operationType: 'edit',
      icon: 'el-icon-edit',
      disabled: true,
      handleClick: this.openBatchEditPriceDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'billing.batchSetDiscount',
      operationType: 'discount',
      icon: 'el-icon-edit',
      handleClick: this.openBatchSetDiscountDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'billing.roundDown',
      operationType: 'roundDown',
      icon: 'el-icon-edit',
      disabled: true,
      handleClick: this.handleRoundDownClick
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      permissionCode: 'finance:bill:deleteBillDetail',
      plain: true,
      disabled: true,
      handleClick: this.batchDelete
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'finance:bill:importData',
      handleClick: (): void => {
        this.openImportDialog();
      }
    }
  ];

  public selectedRows: Array<ProductRequestList> = [];

  public selectProductVisible = false;

  public batchEditPriceVisible = false;

  public batchSetDiscountVisible = false;

  public importVisible = false;

  public queryItemsOption: Array<OsQueryItemOption<ProductRequestQuery>> = [
    {
      type: 'Input',
      field: 'projectName',
      label: 'project.name',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'pointName',
      label: 'projectProduct.pointName',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'itemCode',
      label: 'product.code',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'itemName',
      label: 'product.name',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'itemType',
      label: 'billing.itemType',
      option: {
        placeholder: 'common.select'
      },
      optionData: billingService.getBillingItemTypes
    },
    {
      type: 'Select',
      field: 'priceUnit',
      label: 'billing.priceUnit',
      option: {
        placeholder: 'common.select'
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'attritionFlag',
      label: 'projectProduct.isRemake',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          },
          {
            label: translation('common.no'),
            value: 0
          }
        ];
      }
    }
  ];

  public queryForm: Partial<ProductRequestQuery> = {};

  private readonly canEditCells: Array<Partial<keyof ProductRequestList>> = [
    'count',
    'priceBeforeTax',
    'priceIncludingTax',
    'amountExcludingTax',
    // 'taxAmount',
    // 'taxRate',
    'discountAmount',
    'discountRate',
    'discountRemark',
    'remark'
  ];

  @InjectReactive()
  private readonly customerId!: number;

  public get selectedAmountExcludingTax(): string {
    let res = new Decimal(0);
    this.selectedRows.forEach(x => {
      res = res.add(new Decimal(x.amountExcludingTax));
    });
    return res.toFixed(2);
  }

  public async getPriceUnits(): Promise<void> {
    const unitOptionData = await unitsMeasureService.getPriceUnits();
    this.queryItemsOption.forEach(item => {
      if (item.field === 'priceUnit') {
        item.optionData = unitOptionData;
      }
    });
  }

  public activated(): void {
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.queryForm = {};
      this.clearSelection();
    }
  }

  @Watch('tableData')
  public watchTableData(): void {
    this.filterData();
  }

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'billing-make');
    this.getPriceUnits();
    if (this.tableData.length > 0) {
      this.tableData.forEach(x => {
        this.initInputDynamicProp(x, this.canEditCells);
      });
      this.synchronizationData();
      this.handleSortChange(this.tableOptions.defaultSort!);
    }
  }

  public handleSelectionChange(selectedData: Array<ProductRequestList>): void {
    this.selectedRows = selectedData;
  }

  public filterData(): void {
    this.synchronizationData();
    if (this.queryForm.projectName) {
      this.filteredData = this.filteredData.filter(x => fuzzyQueryStr(this.queryForm.projectName!, x.projectName!));
    }
    if (this.queryForm.pointName) {
      this.filteredData = this.filteredData.filter(x => fuzzyQueryStr(this.queryForm.pointName!, x.pointName!));
    }
    if (this.queryForm.itemCode) {
      this.filteredData = this.filteredData.filter(x => fuzzyQueryStr(this.queryForm.itemCode!, x.itemCode!));
    }
    if (this.queryForm.itemName) {
      this.filteredData = this.filteredData.filter(x => fuzzyQueryStr(this.queryForm.itemName!, x.itemName!));
    }
    if (this.queryForm.itemType) {
      this.filteredData = this.filteredData.filter(x => x.itemType === this.queryForm.itemType);
    }
    if (this.queryForm.priceUnit) {
      this.filteredData = this.filteredData.filter(x => x.priceUnit === this.queryForm.priceUnit);
    }
    if (this.queryForm.attritionFlag || this.queryForm.attritionFlag === 0) {
      this.filteredData = this.filteredData.filter(x => x.attritionFlag === this.queryForm.attritionFlag);
    }
    this.clearSelection();
    this.pagingData();
  }

  public pagingData(): void {
    this.totalData = this.filteredData.length;
    this.tableOptions.data = [];
    this.filteredData.forEach((item, index) => {
      item.index = index + 1;
    });
    this.tableOptions.data.push(
      ...this.filteredData.slice(
        (this.paging.currentPage - 1) * this.paging.showCount,
        this.paging.currentPage * this.paging.showCount
      )
    );
  }

  public handleSortChange(sortOptions: SortOptions<ProductRequestList>): void {
    if (sortOptions.order === 'ascending') {
      this.filteredData = this.filteredData.sort((a, b) => {
        return (a[sortOptions.prop] as string).localeCompare(b[sortOptions.prop] as string, 'zh-CN');
      });
    } else if (sortOptions.order === 'descending') {
      this.filteredData = this.filteredData.sort((a, b) => {
        return (b[sortOptions.prop] as string).localeCompare(a[sortOptions.prop] as string, 'zh-CN');
      });
    }
    this.pagingData();
  }

  /**
   * 选择项次确认后的回调
   */
  public handleSelectedProjectItem(projectItems: Array<InviceProjectProductItems>): void {
    // 去除已添加的
    const itemIds = this.tableData.map(x => x.itemId);
    const toAddDatas = projectItems.filter(x => !itemIds.includes(x.itemId));

    this.tableData.unshift(
      ...toAddDatas.map(x => {
        // 初始化数据
        const item: Partial<ProductRequestList> = {
          ...x,
          priceBeforeTax: x.priceBeforeTax,
          priceIncludingTax: x.priceBeforeTax,
          taxRate: 0,
          amountExcludingTax: 0,
          amountIncludingTax: 0,
          discountAmount: 0,
          discountRate: 0,
          discountRemark: '',
          taxAmount: 0,
          count: x.saleCount - x.invoiceCount
        };

        // // 当计价单位为非平方米时，单位面积默认为1平方米
        // item.unitArea =
        //   item.priceUnit === ChargeUnitEnum.squareMeter
        //     ? decimalToNumber6(new Decimal(item.finishHeight!).div(1000).mul(new Decimal(item.finishWidth!).div(1000)))
        //     : 1;
        // 判断是否面积计价
        if (item.areaPriceFlag === 1) {
          // 计价数量(settlementArea) = 结算数量*单位面积
          item.settlementArea = decimalToNumber6(new Decimal(item.count!).mul(new Decimal(item.unitArea!)));
        } else {
          item.settlementArea = item.count;
        }

        // 初始化要展示的数据
        this.initInputDynamicProp(item, this.canEditCells);
        this.handlePriceBeforeTaxChange(item as ProductRequestList);
        return item;
      })
    );

    // 添加完产品项次后回到第一页
    this.paging.currentPage = 1;
    this.filterData();
  }

  /**
   * 批量修改金额的回调
   * @param params 要批改的金额以及属性
   */
  public handleBatchEditPrice(params: BatchEditPriceForm): void {
    for (const item of this.selectedRows) {
      if (params.prop === 'priceBeforeTax') {
        item.priceBeforeTax = params.amount;
        this.handlePriceBeforeTaxChange(item);
        continue;
      }
      if (params.prop === 'priceIncludingTax') {
        item.priceIncludingTax = params.amount;
        this.handlePriceIncludingTaxChange(item);
        continue;
      }

      if (params.prop === 'amountExcludingTax') {
        this.calAmountExcludingTaxByStrategy(params, item);
        continue;
      }
    }
    if (params.prop === 'amountExcludingTax' && params.strategy !== 'default') {
      this.$nextTick(() => {
        // 批量分配后容差处理
        this.toleranceTreatment(params.amount);
      });
    }
  }

  /**
   * 处理可编辑单元格的点击事件
   * @param prop 处理
   * @param rowData 当前行的数据
   */
  public handlePropClick(prop: string, rowData: { [P: string]: any }): void {
    this.$set(rowData, `${prop}Input`, true);
  }

  /**
   * 批量设置优惠确认
   */
  public handleDiscountConfirm(form: BatchSetDiscountForm): void {
    if (this.selectedRows.length > 0) {
      this.setDiscount(form, this.selectedRows);
      return;
    }
    this.setDiscount(form, this.tableData as Array<ProductRequestList>);
    this.synchronizationData();
    this.pagingData();
  }

  public downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    billingService
      .downloadMakeTemplate()
      .then((blob: any) => {
        downloadFileByBlob(translation('projectProduct.templateName'), blob);
      })
      .catch((error: string) => {
        Message.error(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }

  public importSuccess(path: string): void {
    billingService
      .importMakeDetails(path, this.customerId)
      .then(res => {
        this.importVisible = false;
        if (res.data?.dataList?.length > 0) {
          const toAddDatas = res.data.dataList.map(x => {
            const findAddedRes = this.tableData.find(y => y.itemId! === x.itemId);
            // 初始化要开票的项次基础数据
            const item: Partial<ProductRequestList> = {
              ...x,
              id: findAddedRes?.id,
              priceBeforeTax: x.priceBeforeTax ?? 0,
              priceIncludingTax: x.priceBeforeTax ?? 0,
              taxRate: 0,
              amountExcludingTax: x.amountExcludingTax ?? 0,
              amountIncludingTax: 0,
              discountAmount: x.discountAmount ?? 0,
              discountRate: x.discountRate ?? 0,
              discountRemark: x.discountRemark ?? '',
              taxAmount: 0,
              count: x.count,
              areaPriceFlag: x.areaPriceFlag ?? 0
            };
            // // 当计价单位为非平方米时，单位面积默认为1平方米
            // item.unitArea =
            //   item.priceUnit === ChargeUnitEnum.squareMeter
            //     ? decimalToNumber6(
            //         new Decimal(item.finishHeight!).div(1000).mul(new Decimal(item.finishWidth!).div(1000))
            //       )
            //     : 1;
            // 判断是否面积计价
            if (item.areaPriceFlag === 1) {
              // 计价数量(settlementArea) = 结算数量*单位面积
              item.settlementArea = decimalToNumber6(new Decimal(item.count!).mul(new Decimal(item.unitArea!)));
            } else {
              item.settlementArea = item.count;
            }

            // 未返回未税单价
            if (!item.priceBeforeTax) {
              // 计算未税单价
              item.priceBeforeTax = Number(new Decimal(item.amountExcludingTax!).div(item.settlementArea!));
            }
            // 计算价格
            if (!isNullOrUndefinedForBaseType(item.priceBeforeTax)) {
              this.handlePriceBeforeTaxChange(item as ProductRequestList);
            } else if (!isNullOrUndefinedForBaseType(item.amountExcludingTax)) {
              this.handleAmountExcludingTaxChange(item as ProductRequestList);
            }

            if (!isNullOrUndefinedForBaseType(item.discountRate)) {
              this.handleDiscountRateChange(item as ProductRequestList);
            } else if (!isNullOrUndefinedForBaseType(item.discountAmount)) {
              this.handleDiscountAmountChange(item as ProductRequestList);
            }

            // 初始化动态可编辑的字段
            this.initInputDynamicProp(item, this.canEditCells);
            return item;
          });
          const toAddItemIds = toAddDatas.map(x => x.itemId!);

          // 移除列表中与导入数据重复的项次，使用导入的项次替代
          this.batchDeleteByItemId(toAddItemIds);
          this.tableData.unshift(...toAddDatas);

          // 添加完产品项次后回到第一页
          this.paging.currentPage = 1;
          this.filterData();
        }
        if (res.data.errorList.length > 0) {
          handleImportError(res);
          return;
        }
        Message.success(translation('dialog.importSuccess'));
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(values: Array<ProductRequestList>): void {
    const dynamicControlsBtnType = ['edit', 'roundDown', 'batchDelete'];
    this.operationOptions
      .filter(x => dynamicControlsBtnType.includes(x.operationType))
      .forEach(x => {
        x.disabled = values.length === 0;
      });
  }

  private batchDelete(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          // 对于尚未保存的明细，使用itemId即项次主键进行删除
          const itemIds = this.selectedRows.filter(x => !x.id).map(x => x.itemId);
          if (itemIds.length > 0) {
            this.batchDeleteByItemId(itemIds);
          }

          // 对于已保存过的，需要调用接口进行删除
          const addedDatas = this.selectedRows.filter(x => !!x.id);

          if (addedDatas.length > 0) {
            await billingService.batchDeleteBillingDetails(addedDatas.map(x => x.id!));
            this.batchDeleteByItemId(addedDatas.map(x => x.itemId));
          }

          Message.success(translation('operationRes.deleteSuccess'));
          this.$emit('shop-deleted', this.selectedRows);
          this.clearSelection();
          this.pagingData();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private clearSelection(): void {
    // 处理勾选问题
    (this.$refs.makeTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private openSelectProductDialog(): void {
    this.selectProductVisible = true;
  }

  private openBatchEditPriceDialog(): void {
    this.batchEditPriceVisible = true;
  }

  private openBatchSetDiscountDialog(): void {
    this.batchSetDiscountVisible = true;
  }

  /**
   * 抹零操作
   */
  private handleRoundDownClick(): void {
    this.roundDown(this.selectedRows);
    this.clearSelection();
  }

  /**
   * 同步filteredData 和 tableData的数据
   */
  private synchronizationData(): void {
    this.filteredData = this.tableData;
  }

  /**
   * 未税金额需要针对不同分配策略设置
   * @param params
   * @param item
   */
  private calAmountExcludingTaxByStrategy(params: BatchEditPriceForm, item: ProductRequestList): void {
    if (params.strategy === 'default') {
      item.amountExcludingTax = params.amount;
      this.handleAmountExcludingTaxChange(item);
    } else if (params.strategy === 'byArea') {
      // 按面积分配时，根据每个项次占总面积之和的比例计算未税金额
      const totalArea = this.selectedRows.reduce((prev, current) => prev + current.settlementArea, 0);
      if (totalArea !== 0) {
        item.amountExcludingTax = decimalToNumber2(
          new Decimal(params.amount).mul(new Decimal(item.settlementArea).div(new Decimal(totalArea)))
        );
        this.handleAmountExcludingTaxChange(item);
      } else {
        messageError(new Error('总面积之和不允许为0，请检查选中的项次'));
      }
    } else if (params.strategy === 'byNum') {
      // 按数量分配时，根据每个项次占总数量之和的比例计算未税金额
      const totalNum = this.selectedRows.reduce((prev, current) => prev + current.count, 0);
      if (totalNum !== 0) {
        item.amountExcludingTax = decimalToNumber2(
          new Decimal(params.amount).mul(new Decimal(item.count).div(new Decimal(totalNum)))
        );
        this.handleAmountExcludingTaxChange(item);
      } else {
        messageError(new Error('总数量之和不允许为0，请检查选中的项次'));
      }
    }
  }

  private toleranceTreatment(distributionAmount: number): void {
    if (distributionAmount > 0) {
      let amountExcludingTax = 0;
      this.selectedRows.forEach(item => {
        amountExcludingTax = decimalToNumber2(
          new Decimal(amountExcludingTax).add(new Decimal(item.amountExcludingTax))
        );
      });
      const differenceNum = decimalToNumber2(
        new Decimal(amountExcludingTax.toFixed(2)).sub(new Decimal(distributionAmount))
      );
      if (this.selectedRows.length > 0) {
        const len = this.selectedRows.length - 1;
        this.selectedRows[len].amountExcludingTax = decimalToNumber6(
          new Decimal(this.selectedRows[len].amountExcludingTax).sub(new Decimal(differenceNum))
        );
        this.handleAmountExcludingTaxChange(this.selectedRows[len]);
      }
    }
  }

  /**
   * 设置优惠
   * @param form
   * @param targetData
   */
  private setDiscount(form: BatchSetDiscountForm, targetData: Array<ProductRequestList>): void {
    if ((form.discountAmount && form.discountRate) || form.discountAmount) {
      targetData.forEach(x => {
        x.discountRemark = form.discountRemark;
        // 如果填写的优惠金额大于未税金额，将自动设置为全额优惠
        if (form.discountAmount > x.amountExcludingTax + x.discountAmount) {
          x.discountRate = 100;
          this.handleDiscountRateChange(x);
        } else {
          x.discountAmount = form.discountAmount;

          this.handleDiscountAmountChange(x);
        }
      });
    } else if (form.discountRate) {
      targetData.forEach(x => {
        x.discountRate = form.discountRate;
        x.discountRemark = form.discountRemark;
        this.handleDiscountRateChange(x);
      });
    }
  }

  private openImportDialog(): void {
    this.importVisible = true;
  }

  /**
   * 通过项次id批量删除tableData的数据
   * @param itemIds
   */
  private batchDeleteByItemId(itemIds: Array<number>): void {
    const filteredData = this.tableData.filter(x => !itemIds.includes(x.itemId!));
    // 对于prop.sync的数据只能使用vue包装过的数组处理方法，先清除再添加
    this.tableData.splice(0, this.tableData.length);
    this.tableData.push(...filteredData);
  }
}
