import { billingService, currencyService, customerService, workflowDesignService } from '@/api';
import { CurrencyList, CustomerResource, SaveBilling, WorkflowDesignResource } from '@/resource/model';
import { messageError, messageErrors, translation } from '@/utils';
import { ElForm } from 'element-ui/types/form';
import { Component, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import Make from './make/make.vue';
import Install from './install/install.vue';
import PackageTransport from './package-transport/package-transport.vue';
import Other from './other/other.vue';
import InvoiceInfo from './invoice-info/invoice-info.vue';
import DetailedSummary from './detailed-summary/detailed-summary.vue';
import { Message, Notification } from 'element-ui';
import { Route } from 'vue-router';
import { InvoiceColorEnum } from '@/resource/enum';
import Decimal from 'decimal.js';
import { TagsViewModule } from '@/store/modules/tags-view';
import { SelectCurrency, InitiateApproval } from '@/views/dialogs';
import { ApiError } from '@/api/axios';
type ActiveTab = 'make' | 'package' | 'install' | 'other';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'AddBilling',
  components: { Make, Install, PackageTransport, Other, InvoiceInfo, DetailedSummary, SelectCurrency, InitiateApproval }
})
export default class AddBilling extends Vue {
  public activeTab: ActiveTab = 'make';

  /**
   * 数据是否有更新
   */
  public dataIsEdited = false;

  public title = '';

  public saveLoading = false;

  public currencyTypeDialogVisible = false;

  public id: number | null = null;

  public customers: Array<CustomerResource> = [];

  public paymentModes: Array<{ label: string; value: string }> = billingService.getPaymentModes();

  public currencyTypes: Array<{ label: string; value: string }> = billingService.getCurrencyTypes();

  public billingForm: Partial<SaveBilling> = {
    customerId: undefined,
    settlementMethod: undefined,
    exchangeRate: undefined,
    currencyId: undefined,
    currencyName: '',
    remark: '',
    productRequestList: [],
    customerName: '',
    installRequestList: [],
    packageAndLogisticsRequestList: [],
    otherRequestList: [],
    billInvoiceRequestList: []
  };

  public formRules: { [P in keyof Partial<SaveBilling>]: Array<object> } = {
    customerId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectCustomer')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    currencyName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectSettleCurrency')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    exchangeRate: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.inputSettleExchangeRate')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    settlementMethod: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectPaymentMode')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  /**
   * 审批
   */
  public businessId: number = 0;
  public approvalDialogVisible: boolean = false;
  public workflowDesign: WorkflowDesignResource = {} as WorkflowDesignResource;

  public get isShowInvoice(): boolean {
    return this.amountExcludingTax > 0 || (this.billingForm as Partial<SaveBilling>).billInvoiceRequestList!.length > 0;
  }

  /**
   * 未税金额合计
   */
  public get amountExcludingTax(): number {
    let res = new Decimal(0);
    this.billingForm.otherRequestList?.forEach(x => {
      res = res.add(new Decimal(x.amountExcludingTax));
    });
    this.billingForm.installRequestList?.forEach(x => {
      res = res.add(new Decimal(x.amountExcludingTax));
    });
    this.billingForm.packageAndLogisticsRequestList?.forEach(x => {
      res = res.add(new Decimal(x.amountExcludingTax));
    });
    this.billingForm.productRequestList?.forEach(x => {
      res = res.add(new Decimal(x.amountExcludingTax));
    });
    return res.toNumber();
  }

  /**
   * 发票含税金额合计
   */
  public get invoiceAmountIncludingTax(): number {
    let res = new Decimal(0);
    this.billingForm.billInvoiceRequestList?.forEach(x => {
      res = res.add(new Decimal(x.amountIncludingTax));
    });
    return res.toNumber();
  }

  /**
   * 发票未税金额合计
   */
  public get invoiceAmountExcludingTax(): number {
    let res = new Decimal(0);
    this.billingForm.billInvoiceRequestList?.forEach(x => {
      res = res.add(new Decimal(x.amountExcludingTax));
    });
    return res.toNumber();
  }

  /**
   * 税额合计
   */
  public get invoiceTaxAmount(): number {
    let res = new Decimal(0);
    // this.billingForm.billInvoiceRequestList?.forEach(x => {
    //   res = res.add(new Decimal(x.taxAmount));
    // });
    res = new Decimal(this.invoiceAmountIncludingTax).sub(new Decimal(this.invoiceAmountExcludingTax));
    return res.toNumber();
  }

  @ProvideReactive()
  private customerId = 0;

  @ProvideReactive()
  private customerName = '';

  /**
   * 打开选择币别弹窗
   */
  public openSelectCurrencyTypeDialog(): void {
    this.currencyTypeDialogVisible = true;
  }
  /**
   * 选中后数据处理
   */
  public handleSelectedCurrency(rowData: CurrencyList): void {
    this.billingForm.currencyId = rowData.id;
    this.billingForm.currencyName = rowData.name;
    if (rowData.name === '人民币') {
      this.billingForm.exchangeRate = 1;
      (this.$refs.billingForm as ElForm).validateField('exchangeRate');
    }
  }

  public async getCurrentType(): Promise<void> {
    const currentOptionData = await currencyService.getCurrencyList({}, { currentPage: 1, showCount: 50 });
    currentOptionData.data.forEach((item: any) => {
      const id: number = item.id;
      const name: string = item.name;
      switch (name) {
        case '人民币':
          // 基本单位
          this.billingForm.currencyId = id;
          this.billingForm.currencyName = name;
          this.billingForm.exchangeRate = 1;
          break;
        default:
          break;
      }
    });
  }

  public async activated(): Promise<void> {
    // 判断是否复制
    if (this.$route.query.type === 'isCopy') {
      if (Number(this.$route.query.id)) {
        this.id = Number(this.$route.query.id);
        await this.getBillingDetails(true);
        this.id = null;
      }
      return;
    }

    if (this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);
      if (this.id) {
        this.getBillingDetails(false);
      }
    }
  }
  public created(): void {
    this.getCustomers();
    if (!Number(this.$route.query.id)) {
      this.title = 'billing.addBilling';
      this.getCurrentType();
      this.billingForm.settlementMethod = this.paymentModes[2].value as any;
      return;
    }
    this.title = 'billing.editBilling';
  }

  /**
   * 校验金额 范围
   * 合法范围 [-99999999,99999999]
   * @returns  Boolean
   */
  public verificationValueRange(): boolean {
    const errors: Array<ApiError> = [];
    // 制作明细
    this.billingForm.productRequestList?.forEach((item, index) => {
      const lineNum = index + 1;
      if (this.isInRange(item.priceBeforeTax)) {
        errors.push({
          message: `”制作明细“第${lineNum}行，”未税单价“字段超出限定范围[-99999999,99999999]`,
          level: 1
        });
      }
      if (this.isInRange(item.priceIncludingTax)) {
        errors.push({
          message: `”制作明细“第${lineNum}行，”含税单价“字段超出限定范围[-99999999,99999999]`,
          level: 1
        });
      }
      if (this.isInRange(item.amountExcludingTax)) {
        errors.push({
          message: `”制作明细“第${lineNum}行，”未税金额“字段超出限定范围[-99999999,99999999]`,
          level: 1
        });
      }
    });
    // 包装运输明细
    this.billingForm.packageAndLogisticsRequestList?.forEach((item, index) => {
      const lineNum = index + 1;
      if (this.isInRange(item.priceBeforeTax)) {
        errors.push({
          message: `”包装运输明细“第${lineNum}行，”未税单价“字段超出限定范围[-99999999,99999999]`,
          level: 1
        });
      }
      if (this.isInRange(item.priceIncludingTax)) {
        errors.push({
          message: `包装运输明细“第${lineNum}行，”含税单价“字段超出限定范围[-99999999,99999999]`,
          level: 1
        });
      }
      if (this.isInRange(item.amountExcludingTax)) {
        errors.push({
          message: `”包装运输明细“第${lineNum}行，”未税金额“字段超出限定范围[-99999999,99999999]`,
          level: 1
        });
      }
    });
    // 安装明细
    this.billingForm.installRequestList?.forEach((item, index) => {
      const lineNum = index + 1;
      if (this.isInRange(item.priceBeforeTax)) {
        errors.push({
          message: `”安装明细“第${lineNum}行，”未税单价“字段超出限定范围[-99999999,99999999]`,
          level: 1
        });
      }
      if (this.isInRange(item.priceIncludingTax)) {
        errors.push({
          message: `”安装明细“第${lineNum}行，”含税单价“字段超出限定范围[-99999999,99999999]`,
          level: 1
        });
      }
      if (this.isInRange(item.amountExcludingTax)) {
        errors.push({
          message: `”安装明细“第${lineNum}行，”未税金额“字段超出限定范围[-99999999,99999999]`,
          level: 1
        });
      }
    });
    // 其他明细
    this.billingForm.otherRequestList?.forEach((item, index) => {
      const lineNum = index + 1;
      if (this.isInRange(item.priceBeforeTax)) {
        errors.push({
          message: `”其他明细“第${lineNum}行，”未税单价“字段超出限定范围[-99999999,99999999]`,
          level: 1
        });
      }
      if (this.isInRange(item.priceIncludingTax)) {
        errors.push({
          message: `”其他明细“第${lineNum}行，”含税单价“字段超出限定范围[-99999999,99999999]`,
          level: 1
        });
      }
      if (this.isInRange(item.amountExcludingTax)) {
        errors.push({
          message: `”其他明细“第${lineNum}行，”未税金额“字段超出限定范围[-99999999,99999999]`,
          level: 1
        });
      }
    });

    if (errors.length > 0) {
      messageErrors(errors);
    }
    return errors.length > 0;
  }

  public async saveBilling(): Promise<void> {
    if (this.amountExcludingTax < 0) {
      Message.error(translation('billing.amountExcludingTaxError'));
      return;
    }

    const validateRes = await this.validateData();
    if (!validateRes) {
      return;
    }

    if (this.verificationValueRange()) {
      return;
    }

    try {
      this.saveLoading = true;
      if (this.id) {
        await billingService.put(this.billingForm);
      } else {
        await billingService.post(this.billingForm);
      }
      Message.success(translation('operationRes.saveSuccess'));
      this.close();
    } catch (error) {
      messageErrors(error as any);
    } finally {
      this.saveLoading = false;
    }
  }

  public async saveAndPushBilling(): Promise<void> {
    try {
      this.saveLoading = true;
      if (this.id) {
        return new Promise((resolve, reject) => {
          billingService
            .put(this.billingForm)
            .then(() => {
              this.businessId = this.id!;
              resolve();
            })
            .catch(err => {
              reject(err);
            });
        });
      } else {
        return new Promise((resolve, reject) => {
          billingService
            .post(this.billingForm)
            .then(id => {
              this.businessId = id;
              resolve();
            })
            .catch(err => {
              reject(err);
            });
        });
      }
    } catch (error) {
      messageErrors(error as any);
    } finally {
      this.saveLoading = false;
    }
  }

  private async submitApply(id: number): Promise<void> {
    if (this.amountExcludingTax < 0) {
      Message.error(translation('billing.amountExcludingTaxError'));
      return;
    }
    const validateRes = await this.validateData();
    if (!validateRes) {
      return;
    }
    if (this.verificationValueRange()) {
      return;
    }
    this.workflowDesign = (await workflowDesignService.getByTag('billTaskHandler')) ?? {};
    if (this.workflowDesign.id) {
      this.businessId = id;
      this.approvalDialogVisible = true;
    } else {
      this.$message.warning('当前业务没有设置审批任务');
      this.close();
    }
  }

  private close(): void {
    this.dataIsEdited = true;
    this.$router.go(-1);
    TagsViewModule.DelView(this.$route);
  }

  private isInRange(num: number): boolean {
    return !(-99999999 <= num && num <= 99999999);
  }

  private getCustomers(): void {
    customerService
      .getAllUsingCustomer()
      .then(res => {
        this.customers = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('billingForm.customerId')
  private handleCustomerChange(newValue: number, oldValue: number | undefined): void {
    // TODO 添加判断防止客户名称为空
    this.billingForm.customerName =
      this.customers.length > 0
        ? this.customers.find(x => x.id === this.billingForm.customerId)?.companyName
        : this.billingForm.customerName;
    this.customerId = this.billingForm.customerId!;
    this.customerName = this.billingForm.customerName!;
    // 切换客户时，清空所有的明细
    if (oldValue) {
      this.billingForm.billInvoiceRequestList = [];
      this.billingForm.packageAndLogisticsRequestList = [];
      this.billingForm.installRequestList = [];
      this.billingForm.productRequestList = [];
      this.billingForm.otherRequestList = [];
    }
  }

  /**
   * 发票未税开票金额
   * @returns
   */
  private invoiceAmountExcludTax(): number {
    return (
      this.billingForm.billInvoiceRequestList
        ?.reduce((x, y) => x.add(new Decimal(y.amountExcludingTax)), new Decimal(0))
        ?.toNumber() ?? 0
    );
  }

  private beforeRouteLeave(to: Route, from: Route, next: Function): void {
    if (to.path === '/billing') {
      to.meta!.needReload = this.dataIsEdited;
    }
    next();
  }

  private async getBillingDetails(isCopy: boolean): Promise<void> {
    billingService
      .getById(this.id as number)
      .then(res => {
        // 判断是否为复制
        if (isCopy) {
          // eslint-disable-next-line no-param-reassign
          res = JSON.parse(JSON.stringify(res).replace(/"id"/g, '"originalId"'));
          // 复制的时候过滤掉红字
          res.billInvoiceInfoResponses = res.billInvoiceInfoResponses.filter(x => x.color === InvoiceColorEnum.blue);
        }

        res.billInvoiceInfoResponses.forEach(x => {
          x.taxAmount = x.amountIncludingTax - x.amountExcludingTax;
        });
        Object.assign(this.billingForm, res);
        this.$nextTick(() => {
          this.billingForm.productRequestList = res.productInfoResponses || [];
          this.billingForm.packageAndLogisticsRequestList = res.packageAndLogisticsInfoResponses || [];
          this.billingForm.installRequestList = res.installInfoResponses || [];
          this.billingForm.otherRequestList = res.otherInfoResponses || [];
          this.billingForm.billInvoiceRequestList = res.billInvoiceInfoResponses || [];
          this.billingForm.billInvoiceRequestList.forEach(invoice => {
            if (isCopy) {
              // eslint-disable-next-line max-nested-callbacks
              invoice.fileList = JSON.parse(JSON.stringify(invoice.fileList).replace(/"originalId"/g, '"id"'));
            }
            if (invoice.fileList && invoice.fileList.length > 0) {
              // eslint-disable-next-line max-nested-callbacks
              invoice.fileIdList = invoice.fileList!.map(x => x.id!) || [];
            }
          });
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  private async validateData(): Promise<boolean> {
    try {
      const formValidateRes = await (this.$refs.billingForm as ElForm).validate();
      if (formValidateRes) {
        let errorMsg = '';
        this.billingForm.otherRequestList?.forEach((x, index) => {
          if (!x.projectId) {
            errorMsg += `<br />序号为${index + 1}的 所属项目 不能为空！<br />`;
          }
        });
        if (errorMsg !== '') {
          Notification.error({
            title: translation('dialog.saveFailed'),
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: `<span>${errorMsg}</span><br />`
          });
          return Promise.resolve(false);
        }
      }
      return Promise.resolve(true);
    } catch (error) {
      return Promise.resolve(false);
    }
  }
}
