import { InvoiceResource } from '@/resource/model';
import { Component, InjectReactive, Prop } from 'vue-property-decorator';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsTable } from '@/components';
import { translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { DialogMixin } from '@/mixins/dialog';
import { InvoiceTypeEnum } from '@/resource/enum';

@Component({
  components: {}
})
export default class SelectInvoice extends mixins(DialogMixin) {
  @Prop({ required: true, type: Array })
  public invoices!: Array<InvoiceResource>;

  public tableOption: OsTableOption<InvoiceResource> = {
    loading: false,
    data: [],
    fit: true,
    radioSelection: true
  };
  /**
   * 表格列配置
   */
  public columnOptions: Array<OsTableColumn<InvoiceResource>> = [
    {
      prop: 'companyName',
      label: 'invoice.companyName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'taxCode',
      label: 'invoice.taxCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'invoiceType',
      label: 'invoice.invoiceType',
      minWidth: '120px',
      formatter: (rowData: object): string | undefined => {
        return translation(`invoiceType.${InvoiceTypeEnum[(rowData as InvoiceResource).invoiceType]}`);
      }
    },
    {
      prop: 'bankName',
      label: 'invoice.bankName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'bankAccount',
      label: 'invoice.bankAccount',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'address',
      label: 'invoice.address',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'contactNumber',
      label: 'invoice.contactNumber',
      showOverflowTooltip: true,
      minWidth: '150px'
    }
  ];

  public selectedRow: InvoiceResource | null = null;

  @InjectReactive()
  private readonly customerId!: number;

  public dialogOpen(): void {
    this.tableOption.data = this.invoices;
  }

  public dialogClosed(): void {
    this.clearSelection();
  }

  public handleRadioSelectionChange(selectedValue: InvoiceResource): void {
    this.selectedRow = selectedValue;
  }

  public onSubmit(): void {
    this.$emit('confirm', this.selectedRow);
    this.syncedVisible = false;
  }

  public get radioConfirmDisabled(): boolean {
    return !this.selectedRow;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    this.selectedRow = null;
    (this.$refs.invoiceTable as OsTable).clearRadioSelection();
  }
}
