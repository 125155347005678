import { decimalToNumber2, decimalToNumber6, translation } from '@/utils';
import { Decimal } from 'decimal.js';
import { Message } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';
interface SourcePrice {
  /**
   * 未税金额
   */
  amountExcludingTax: number;
  /**
   * 含税金额
   */
  amountIncludingTax: number;
  /**
   * 数量
   */
  count: number;
  /**
   * 折扣额
   */
  discountAmount: number;
  /**
   * 优惠税率
   */
  discountRate: number;
  /**
   * 未税单价
   */
  priceBeforeTax: number;
  /**
   * 含税单价
   */
  priceIncludingTax: number;
  /**
   * 税额
   */
  taxAmount: number;
  /**
   * 税率
   */
  taxRate: number;

  /**
   *  剩余可开票的数量 由选择项次时，前端自己计算得来
   */
  // remainingCount?: number;

  saleCount?: number;
}
@Component
export class CalculatePriceMixin extends Vue {
  /**
   * 计价数量发生变化
   * @param rowData
   */
  public handleCountChange(rowData: SourcePrice): void {
    if (!rowData.count) {
      rowData.count = 0;
    }
    if (rowData.saleCount && rowData.count > rowData.saleCount) {
      rowData.count = rowData.saleCount;
      Message.warning(translation('billing.countExceedTip'));
    }

    // 计算优惠前未税金额
    const beforeDiscountAmount = new Decimal(rowData.priceBeforeTax).mul(new Decimal(rowData.count));

    // 优惠金额 = 优惠前未税金额 * 优惠比例
    rowData.discountAmount = decimalToNumber2(beforeDiscountAmount.mul(new Decimal(rowData.discountRate).div(100)));

    // 计算未税金额 = 优惠前未税金额 - 优惠金额
    rowData.amountExcludingTax = decimalToNumber2(beforeDiscountAmount.sub(new Decimal(rowData.discountAmount)));

    // 计算税额 = 未税金额 * 税率
    rowData.taxAmount = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).mul(new Decimal(rowData.taxRate).div(100))
    );

    // 含税金额 = 未税金额 + 税额
    rowData.amountIncludingTax = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.taxAmount))
    );
  }

  /**
   *  未税单价发生变化
   * @param rowData
   */
  public handlePriceBeforeTaxChange(rowData: SourcePrice): void {
    if (!rowData.priceBeforeTax) {
      rowData.priceBeforeTax = 0;
    }
    // 含税单价 = 未税单价 * (1 + 税率)
    rowData.priceIncludingTax = decimalToNumber6(
      new Decimal(rowData.priceBeforeTax).mul(new Decimal(rowData.taxRate).div(100).add(1))
    );

    // 计算优惠前未税金额
    const beforeDiscountAmount = new Decimal(rowData.priceBeforeTax).mul(new Decimal(rowData.count));

    // 优惠金额 = 优惠前未税金额 * 优惠比例
    rowData.discountAmount = decimalToNumber2(beforeDiscountAmount.mul(new Decimal(rowData.discountRate).div(100)));

    // 计算未税金额 = 优惠前未税金额 - 优惠金额
    rowData.amountExcludingTax = decimalToNumber2(beforeDiscountAmount.sub(new Decimal(rowData.discountAmount)));

    // 计算税额 = 未税金额 * 税率
    rowData.taxAmount = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).mul(new Decimal(rowData.taxRate).div(100))
    );

    // 含税金额 = 未税金额 + 税额
    rowData.amountIncludingTax = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.taxAmount))
    );
  }

  /**
   * 含税单价发生变化
   * @param rowData
   */
  public handlePriceIncludingTaxChange(rowData: SourcePrice): void {
    rowData.priceBeforeTax = rowData.priceIncludingTax;
    this.handlePriceBeforeTaxChange(rowData);
    if (!rowData.priceIncludingTax) {
      rowData.priceIncludingTax = 0;
    }
    // 计算税率 = (含税单价 - 未税单价) / 未税单价
    if (rowData.priceBeforeTax !== 0) {
      rowData.taxRate = decimalToNumber2(
        new Decimal(rowData.priceIncludingTax)
          .sub(new Decimal(rowData.priceBeforeTax))
          .div(new Decimal(rowData.priceBeforeTax))
          .mul(100)
      );
    }

    // 计算税额 = 未税金额 * 税率
    rowData.taxAmount = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).mul(new Decimal(rowData.taxRate).div(100))
    );

    // 含税金额 = 未税金额 + 税额
    rowData.amountIncludingTax = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.taxAmount))
    );
  }

  /**
   * 税率发生变化
   * @param rowData
   */
  public handleTaxRateChange(rowData: SourcePrice): void {
    if (!rowData.taxRate) {
      rowData.taxRate = 0;
    }
    // 含税单价 = 未税单价 * (1 + 税率)
    rowData.priceIncludingTax = decimalToNumber6(
      new Decimal(rowData.priceBeforeTax).mul(new Decimal(rowData.taxRate).div(100).add(1))
    );

    // 计算优惠前未税金额
    const beforeDiscountAmount = new Decimal(rowData.priceBeforeTax).mul(new Decimal(rowData.count));

    // 优惠金额 = 优惠前未税金额 * 优惠比例
    rowData.discountAmount = decimalToNumber2(beforeDiscountAmount.mul(new Decimal(rowData.discountRate).div(100)));

    // 计算未税金额 = 优惠前未税金额 - 优惠金额
    rowData.amountExcludingTax = decimalToNumber2(beforeDiscountAmount.sub(new Decimal(rowData.discountAmount)));

    // 计算税额 = 未税金额 * 税率
    rowData.taxAmount = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).mul(new Decimal(rowData.taxRate).div(100))
    );

    // 含税金额 = 未税金额 + 税额
    rowData.amountIncludingTax = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.taxAmount))
    );
  }

  /**
   * 优惠比例发生变化
   * @param rowData
   */
  public handleDiscountRateChange(rowData: SourcePrice): void {
    if (!rowData.discountRate) {
      rowData.discountRate = 0;
    }
    // 计算优惠前未税金额
    const beforeDiscountAmount = new Decimal(rowData.priceBeforeTax).mul(new Decimal(rowData.count));

    // 优惠金额 = 优惠前未税金额 * 优惠比例
    rowData.discountAmount = decimalToNumber2(beforeDiscountAmount.mul(new Decimal(rowData.discountRate).div(100)));

    // 计算未税金额 = 优惠前未税金额 - 优惠金额
    rowData.amountExcludingTax = decimalToNumber2(beforeDiscountAmount.sub(new Decimal(rowData.discountAmount)));

    // 计算税额 = 未税金额 * 税率
    rowData.taxAmount = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).mul(new Decimal(rowData.taxRate).div(100))
    );

    // 含税金额 = 未税金额 + 税额
    rowData.amountIncludingTax = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.taxAmount))
    );
  }

  /**
   * 优惠金额发生变化
   * @param rowData
   */
  public handleDiscountAmountChange(rowData: SourcePrice): void {
    if (!rowData.discountAmount) {
      rowData.discountAmount = 0;
    }
    if (rowData.count === 0) {
      Message.warning(translation('billing.inputCount'));
      return;
    }
    if (rowData.priceBeforeTax === 0) {
      Message.warning(translation('billing.inputPriceBeforeTax'));
      return;
    }

    // 计算优惠前未税金额
    const beforeDiscountAmount = new Decimal(rowData.priceBeforeTax).mul(new Decimal(rowData.count));

    // 优惠比例 = 优惠金额 / 优惠前未税金额
    rowData.discountRate = decimalToNumber2(new Decimal(rowData.discountAmount).div(beforeDiscountAmount).mul(100));

    // 计算未税金额 = 优惠前未税金额 - 优惠金额
    rowData.amountExcludingTax = decimalToNumber2(beforeDiscountAmount.sub(new Decimal(rowData.discountAmount)));

    // 计算税额 = 未税金额 * 税率
    rowData.taxAmount = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).mul(new Decimal(rowData.taxRate).div(100))
    );

    // 含税金额 = 未税金额 + 税额
    rowData.amountIncludingTax = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.taxAmount))
    );
  }

  /**
   * 优惠后未税金额发生变化
   * @param rowData
   */
  public handleAmountExcludingTaxChange(rowData: SourcePrice): void {
    if (!rowData.amountExcludingTax) {
      rowData.amountExcludingTax = 0;
      rowData.discountAmount = 0;
      rowData.discountRate = 0;
    }
    /* 
     * 未税金额与开票金额可同时为0
    if (!rowData.amountExcludingTax && !rowData.discountAmount) {
      Message.warning(translation('billing.inputDiscountOrExcludingTax'));
      return;
    } */

    if (rowData.count === 0) {
      Message.warning(translation('billing.inputCount'));
      return;
    }
    // 先算税额和含税金额
    // 计算税额 = 未税金额 * 税率
    rowData.taxAmount = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).mul(new Decimal(rowData.taxRate).div(100))
    );
    // 含税金额 = 未税金额 + 税额
    rowData.amountIncludingTax = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.taxAmount))
    );

    // 然后倒推未税单价、含税单价、优惠金额
    // 优惠前未税金额 = 优惠后未税金额 + 优惠金额 (倒推以优惠金额为标准)
    const beforeDiscountAmount = new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.discountAmount));

    if (rowData.discountAmount) {
      // 优惠比例 = (优惠前未税金额 / 优惠金额) * 100
      rowData.discountRate = decimalToNumber2(new Decimal(rowData.discountAmount).div(beforeDiscountAmount).mul(100));
    }

    // 未税单价 = 优惠前未税金额 / 计价数量
    rowData.priceBeforeTax = decimalToNumber6(beforeDiscountAmount.div(new Decimal(rowData.count)));

    // 含税单价 = 未税单价 * (1 + 税率)
    rowData.priceIncludingTax = decimalToNumber6(
      new Decimal(rowData.priceBeforeTax).mul(new Decimal(rowData.taxRate).div(100).add(1))
    );
  }

  /**
   * 税额发生变化
   * @param rowData
   */
  public handleTaxAmountChange(rowData: SourcePrice): void {
    if (!rowData.taxAmount) {
      rowData.taxAmount = 0;
    }
    // 含税金额 = 未税金额 + 税额
    rowData.amountIncludingTax = decimalToNumber2(
      new Decimal(rowData.amountExcludingTax).add(new Decimal(rowData.taxAmount))
    );

    // 税率 = 税额 / 未税金额
    rowData.taxRate = decimalToNumber2(
      new Decimal(rowData.taxAmount).div(new Decimal(rowData.amountExcludingTax)).mul(100)
    );
  }

  /**
   * 初始化项次内，部分可以动态使用input编辑的属性
   * @param rowObj行对象
   * @param canEditCells 要初始化的列
   */
  public initInputDynamicProp(rowObj: any, canEditCells: Array<string>): void {
    canEditCells.forEach(x => {
      Vue.set(rowObj, `${x}Input`, false);
    });
  }

  /**
   * 向下取整，把小数位加到优惠金额中处理
   * @param items
   */
  public roundDown(items: Array<SourcePrice>): void {
    items.forEach(x => {
      x.discountAmount = decimalToNumber2(
        new Decimal(x.discountAmount ?? 0).add(
          new Decimal(x.amountExcludingTax).sub(new Decimal(Math.floor(x.amountExcludingTax)))
        )
      );
      this.handleDiscountAmountChange(x);
    });
  }
}
