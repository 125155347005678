import { billingService } from '@/api';
import { OsTable, OsTableQuery } from '@/components';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { PagingMixin } from '@/mixins/paging';
import { CustomerProjectList, CustomerProjectQuery } from '@/resource/model';
import { messageError } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SelectCustomerProject extends mixins(DialogMixin, PagingMixin) {
  @Prop({ type: Number, required: true })
  public readonly customerId!: number;

  public tableOption: OsTableOption<CustomerProjectList> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true,
    radioSelection: true
  };
  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<CustomerProjectList>> = [
    {
      prop: 'projectCode',
      label: 'project.code',
      minWidth: '120px'
    },
    {
      prop: 'projectName',
      label: 'project.name',
      minWidth: '150px'
    },
    {
      prop: 'createUserName',
      label: 'project.creator',
      minWidth: '120px'
    },
    {
      prop: 'createTime',
      label: 'project.createTime',
      minWidth: '180px'
    }
  ];
  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<CustomerProjectQuery>> = [
    {
      type: 'Input',
      field: 'projectCode',
      label: 'project.code',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'projectName',
      label: 'project.name',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'project.creator',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    }
  ];
  public selectedRow: CustomerProjectList | null = null;

  private queryForm: Partial<CustomerProjectQuery> = {
    customerId: this.customerId
  };

  public get confirmDisabled(): boolean {
    return !this.selectedRow;
  }

  public dialogOpen(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    (this.$refs.tableQuery as OsTableQuery).resetQuery();
    (this.$refs.projectTable as OsTable).clearRadioSelection();
  }

  public onSubmit(): void {
    this.$emit('confirm', this.selectedRow);
    this.syncedVisible = false;
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.getProjects();
  }

  public handleRadioSelectionChange(selectedData: CustomerProjectList): void {
    this.selectedRow = selectedData;
  }

  public getProjects(): void {
    billingService
      .getProjectsByQuery(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
