import { billingService } from '@/api';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import OsTable, { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { InvoiceColorEnum, InvoiceModeEnum, InvoiceTypeEnum } from '@/resource/enum';
import { BillInvoiceRequestList } from '@/resource/model';
import { messageError, showWarningConfirm, translation } from '@/utils';
import { Message } from 'element-ui';
import { cloneDeep, uniqueId } from 'lodash-es';
import { mixins } from 'vue-class-component';
import { Component, PropSync, Watch } from 'vue-property-decorator';
import AddInvoice from './add-invoice/add-invoice.vue';
@Component({
  components: { AddInvoice }
})
export default class InvoiceInfo extends mixins(PagingMixin) {
  @PropSync('invoiceInfo', {
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  public tableData!: Array<Partial<BillInvoiceRequestList>>;

  public id = Number(this.$route.query.id);

  public tableOptions: OsTableOption<Partial<BillInvoiceRequestList>> = {
    loading: false,
    data: [],
    fit: true,
    rowKey: () => {
      return 'uuid';
    },
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      },
      {
        prop: 'amountIncludingTax',
        fixPlace: 2
      },
      {
        prop: 'taxAmount',
        fixPlace: 2
      }
    ]
  };

  public columnOptions: Array<OsTableColumn<BillInvoiceRequestList>> = [
    {
      type: 'selection',
      prop: 'uuid',
      label: '',
      reserveSelection: true,
      fixed: true,
      width: '58px'
    },
    {
      type: 'index',
      prop: 'id',
      label: 'common.index',
      width: '80px'
    },
    {
      prop: 'color',
      label: 'billing.color',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return translation(`invoiceColor.${InvoiceColorEnum[(rowData as BillInvoiceRequestList).color]}`);
      }
    },
    {
      prop: 'companyName',
      label: 'billing.invoiceTitle',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'invoiceNo',
      label: 'billing.invoiceNumber',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return (rowData as BillInvoiceRequestList).amountExcludingTax?.toFixed(2);
      }
    },
    {
      prop: 'taxAmount',
      label: 'billing.taxAmount',
      minWidth: '160px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return (rowData as BillInvoiceRequestList).taxAmount?.toFixed(2);
      }
    },
    {
      prop: 'amountIncludingTax',
      label: 'billing.amountIncludingTax',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return (rowData as BillInvoiceRequestList).amountIncludingTax?.toFixed(2);
      }
    },
    {
      prop: 'invoiceType',
      label: 'billing.invoiceType',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return translation(`invoiceType.${InvoiceTypeEnum[(rowData as BillInvoiceRequestList).invoiceType]}`);
      }
    },
    {
      prop: 'invoiceMethod',
      label: 'billing.invoiceMode',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return translation(`invoiceMode.${InvoiceModeEnum[(rowData as BillInvoiceRequestList).invoiceMethod]}`);
      }
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public rowOperationOptions: RowOperation<BillInvoiceRequestList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        handleClick: this.openInvoiceEditDialog
      }
    ]
  };

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: '',
      handleClick: this.openInvoiceAddDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.copy',
      operationType: 'copy',
      icon: 'el-icon-document-copy',
      permissionCode: '',
      disabled: true,
      handleClick: this.copyInvoice
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      permissionCode: 'finance:bill:deleteInvoice',
      plain: true,
      disabled: true,
      handleClick: this.batchDelete
    }
  ];

  public selectedRows: Array<BillInvoiceRequestList> = [];

  public invoice: BillInvoiceRequestList | null = null;

  public addInvoiceVisible = false;

  public activated(): void {
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.clearSelection();
    }
  }

  @Watch('tableData', { immediate: true })
  public watchTableData(): void {
    this.resetTableDataUUID();
    this.pagingData();
  }
  public created(): void {
    if (this.tableData.length > 0) {
      this.pagingData();
    }
  }

  public dialogClosed(): void {
    this.invoice = null;
  }

  public handleSelectionChange(selectedData: Array<BillInvoiceRequestList>): void {
    this.selectedRows = selectedData;
  }

  public pagingData(): void {
    this.totalData = this.tableData.length;
    this.tableOptions.data = this.tableData.slice(
      (this.paging.currentPage - 1) * this.paging.showCount,
      this.paging.currentPage * this.paging.showCount
    );
  }
  public resetTableDataUUID(): void {
    this.tableData.forEach(x => {
      x.uuid = this.createUuid();
    });
  }

  public openInvoiceAddDialog(): void {
    this.addInvoiceVisible = true;
  }

  public openInvoiceEditDialog(invoice: BillInvoiceRequestList): void {
    this.invoice = cloneDeep(invoice);
    this.invoice.fileList = this.invoice.fileList || [];
    this.addInvoiceVisible = true;
  }

  public handleAddedInvoice(invoice: Partial<BillInvoiceRequestList>): void {
    invoice.uuid = this.createUuid();
    this.tableData.unshift(invoice);
    this.pagingData();
  }

  public handleEditedInvoice(invoice: Partial<BillInvoiceRequestList>): void {
    this.invoice = null;
    const findIndex = this.tableData.findIndex(x => x.uuid === invoice.uuid);
    Object.assign(this.tableData[findIndex], invoice);
    this.pagingData();
  }

  public copyInvoice(): void {
    const copyRows = cloneDeep(this.selectedRows);
    copyRows.forEach(x => {
      x.uuid = this.createUuid();
      delete x.id;
      x.billInvoiceDetailRequestList.forEach(x => {
        delete x.id;
      });
    });

    this.tableData.push(...copyRows);
    this.pagingData();
    this.clearSelection();
  }

  public batchDelete(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          // 对于尚未保存的发票记录，使用uuids进行删除
          const uuids = this.selectedRows.filter(x => !x.id).map(x => x.uuid);
          if (uuids.length > 0) {
            const filterData = this.tableOptions.data.filter(x => !uuids.includes(x.uuid!));
            // 对于prop.sync的数据只能使用vue包装过的数组处理方法，先清除再添加
            this.tableData.splice(0, this.tableOptions.data.length);
            this.tableData.push(...filterData);
          }

          // 对于已保存过的，需要调用接口进行删除
          const ids = this.selectedRows.map(x => x.id).filter(x => x);
          if (ids.length > 0) {
            await billingService.batchDeleteInvoice(ids as Array<number>);
            const filterData = this.tableOptions.data.filter(x => !ids.includes(x.id!));
            // 对于prop.sync的数据只能使用vue包装过的数组处理方法，先清除再添加
            this.tableData.splice(0, this.tableOptions.data.length);
            this.tableData.push(...filterData);
          }

          this.clearSelection();
          this.pagingData();

          Message.success(translation('operationRes.deleteSuccess'));
          this.$emit('shop-deleted', this.selectedRows);
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<BillInvoiceRequestList>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add') x.disabled = value.length === 0;
    });
  }

  private createUuid(): string {
    return uniqueId('invoice');
  }

  private clearSelection(): void {
    (this.$refs.invoiceTable as OsTable).clearSelection();
    this.selectedRows = [];
  }
}
